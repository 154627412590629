.WidgetPincodeProductDashboard-box {
    background: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.WidgetPincodeProductDashboard-box-header {
    display: flex;
    font-size: 18px;
    margin-bottom: 10px;
}

.WidgetPincodeProductDashboard-box-title {

}