
.page-mobile-header{
    overflow: hidden;
    background-color:white;
    position: fixed;
    top: 0;
    width: 100%;
    /* height: 56px; */
    padding: 10px;
    z-index: 400;
    display: flex;
    border-bottom: solid 1px var(--secondary-btn-clr);
}

.page-mobile-header-title{
    font-size: 20px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page-mobile-content{
    padding-top: 67px;
    margin:10px;
}

.page-mobile-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    z-index: 2;
    padding: 10px;
    border-top: solid 1px var(--secondary-btn-clr);
}