  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    z-index: 2;
    padding:10px;
 }

 .footer-button-save
 {
     width:100%;
     height:46px;
     border-radius: 50px;
     background-color:var(--primary-clr);
     color: white;
     font-size: 17px;
     border: none;
     -webkit-box-shadow: none;
     box-shadow: none;
     outline: none;
 }

 .footer-button-primary
 {
  width:100%;
  height:46px;
  border-radius: 50px;
  background-color: var(--primary-clr);
  color: white;
  font-size: 17px;
  border: none;
  outline-style: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
 }


 .footer-button-secondary
 {
  width:100%;
  height:46px;
  border-radius: 50px;
  background-color:#f7f7f8;
  color: #101928;
  font-size: 17px;
  border: none;
  outline-style: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
 }
