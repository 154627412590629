.productquickedit-page-header-title {
    font-size: 28px;
    padding-top: 5px;
    text-transform: uppercase;
  }

  .productquickedit-prodcut-image {
    width: 110px;
    height: 110px;
    overflow: hidden;
  }

  .productquickedit-prodcut-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .productquickedit-th-image{
    width: 110px;
  }

  .productquickedit-th-mrp{
    width: 110px;
  }
  .productquickedit-th-retailprice{
    width: 110px;
  }