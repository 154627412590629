
:root {
    --primary-clr: rgba(110, 45, 144, 1);
     /* --primary-clr:#f2682a; */
    --secondary-btn-clr:#F0F2F5;
    --btn-border-clr:#d2d6de;
    --btn-imp-clr:#8F4FFA;
  }

  body{
      font-family: 'Nunito';
      background-color: #F0F2F5;
      background-color: var(--secondary-btn-clr);
  }

  #bootstrap-overrides h1{font-family:'Nunito'; 
    font-size: 26px;
    margin-top: 0px;
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 2px;
}

  #bootstrap-overrides h2 {font-family:'Nunito'; font-size: 18px;margin-top: 0px}
  #bootstrap-overrides h3,h4,h5{font-family:'Nunito'; font-size: 18px; margin-top: 0px}

/* old css start */

/* @media (min-width: 768px) {
.sidebar-mini.sidebar-collapse .content-wrapper, .sidebar-mini.sidebar-collapse .right-side, .sidebar-mini.sidebar-collapse .main-footer, .header-margin
{
    padding-top: 64px !important;
    margin-left: 0px !important;

}
} */

.content-wrapper{
    padding:15px;
    padding-top: 81px;
}

.content-header{
height: 40px;
}

.content-header button, .content-header a{
margin-top:0px !important;
height: 32px;
}

#bootstrap-overrides .content-header .btn-default{
background: white;
border: solid 1px rgba(110, 45, 144, 1);
border: solid 1px var(--primary-clr);
color: rgba(110, 45, 144, 1);
color: var(--primary-clr);
}


#bootstrap-overrides  .btn-secondary{
    background: white;
    border: solid 1px rgba(110, 45, 144, 1);
    border: solid 1px var(--primary-clr);
    color: rgba(110, 45, 144, 1);
    color: var(--primary-clr);
}

/* #bootstrap-overrides .content-header button:last-child{
    margin-right:66px !important;
    }

    #bootstrap-overrides .content-header a:last-child{
        margin-right:66px !important;
        } */

.main-footer{
    margin-left: 0px !important;
}

.main-container{
    /* margin:10px; */
}

.box{
    border-top:none;
    box-shadow: none;
    border-radius: 10px;
    background: white;
    padding:10px;
    margin-bottom: 10px;
}

.box-header{
    padding-bottom: 10px;
}

.input-group-addon{
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
    background: #F0F2F5;
    background: var(--secondary-btn-clr);
}

#bootstrap-overrides .btn{
    border-radius: 32px;
    outline: none;
}

/* .btn-success:active */
#bootstrap-overrides .btn-success , #bootstrap-overrides .btn-success:active ,#bootstrap-overrides .btn-primary , #bootstrap-overrides .btn-primary:active{
    background-color: rgba(110, 45, 144, 1);
    background-color: var(--primary-clr);
    border-radius: 32px;
    border:solid 1px rgba(110, 45, 144, 1);
    border:solid 1px var(--primary-clr);
    /* padding-left:20px;
    padding-right:20px; */
    
}

#bootstrap-overrides .btn-default , #bootstrap-overrides .btn-default:active{
    background-color: #F0F2F5;
    background-color: var(--secondary-btn-clr);
    color: #5f6368;
    border-radius: 32px;
    /* border:solid 1px var(--secondary-btn-clr); */
    /* padding-left:20px;
    padding-right:20px; */
}

#bootstrap-overrides .btn-success:hover, #bootstrap-overrides .btn-primary:hover , #bootstrap-overrides .btn-default:hover{
    background-color: white;
    color:rgba(110, 45, 144, 1);
    color:var(--primary-clr);
    border-radius: 32px ;
    border:solid 1px rgba(110, 45, 144, 1);
    border:solid 1px var(--primary-clr);
} 

#bootstrap-overrides .img-btn  .btn-primary svg{
    fill:white;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover
{
    background-color:rgba(110, 45, 144, 1);
    background-color:var(--primary-clr);
    border-color:rgba(110, 45, 144, 1);
    border-color:var(--primary-clr);
}

#bootstrap-overrides  .form-group .input-group .input-group-btn button{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border:solid 1px #d2d6de !important;
    border:solid 1px var(--btn-border-clr) !important;

}

#bootstrap-overrides  .form-group .input-group .input-group-btn button:hover{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border:solid 1px #d2d6de !important;
    border:solid 1px var(--btn-border-clr) !important;

}
/* #bootstrap-overrides .btn-success:active {
    background-color: var(--primary-clr);
    border-radius: 32px;
    border:solid 1px var(--primary-clr) ;
    padding-left:20px;
    padding-right:20px;
    }
     */
/* .input-group-btn button{
    border-radius: 0px !important;
     border: solid 1px #ddd !important;
 }
 
 .input-group-btn button:last-child{
     border-bottom-left-radius: 0px !important;
     border-top-left-radius: 0px !important;
     border-bottom-right-radius: 32px !important;
     border-top-right-radius: 32px !important;
     border: solid 1px #ddd !important;
 } */

 


/* .btn .btn-success .btn-flat .btn-md{
    background-color: var(--primary-clr);
    border-radius: 32px !important;
    border:solid 1px var(--primary-clr) !important;
    padding-left:20px;
    padding-right:20px;
    
} */

/* .btn-primary{
    background-color: var(--primary-clr);
    color: white;
    border-radius: 32px !important;
    border:solid 1px var(--primary-clr) !important;
} */



/* .btn-success:hover, .btn-success:active, .btn-success.hover
{
    background-color: var(--primary-clr);
    border-radius: 32px !important;
    border:solid 1px var(--primary-clr) !important;
    padding-left:20px;
    padding-right:20px;
} */


.form-control{
    border-radius: 32px;
}



.tab-content{
    padding-top: 20px;
    padding-left:10px;
    padding-right: 10px;
}
/* .input-group button{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: solid 1px #ddd !important;
}

.input-group .input-group-addon{
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
} */




/* .btn-group button{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: solid 1px #ddd !important;
}

.btn-group a:first-child, .btn-group button:first-child{
    border-bottom-left-radius: 32px !important;
    border-top-left-radius: 32px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: solid 1px #ddd !important;
}

.btn-group button:last-child{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 32px !important;
    border-top-right-radius: 32px !important;
    border: solid 1px #ddd !important;
} */

.mobileFooter  {
    height:"46px";
    background-color:"white";
    padding-top:"0px";
    position: "fixed";
    left:"0";
    bottom: "0";
    border-top:"solid 1px #ddd";
    width: "100%"}

.styles_modal__gNwvD{
    border-radius: 10px;
}

/* .pagination .active{
    background: var(--primary-clr)
} */

/* .btn-primary:hover, .btn-default:hover, .btn-success:hover, .btn-primary:focus, .btn-default:focus, .btn-success:focus
,.btn-primary:active, .btn-default:active, .btn-success:active{
    color:var(--primary-clr) !important;
    border:solid 1px var(--primary-clr) !important;
    background-color: white !important;
} */

/* old css end*/

.overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    cursor: default;
}

.error-msg{
    color:red;    
}

svg{
    height: 24px;
    fill:#5f6368;
}

.header-pos{
    overflow: hidden;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    display: flex;
    padding:10px;
    background-color:white;
}

/* .header  */
.left{
    display: flex;
    width: 50%;
    justify-content: flex-start;
}

.right{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}

.left-100{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.right-100{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.left-20{
    display: flex;
    width: 20%;
    justify-content: flex-start;
}

.left-30{
    display: flex;
    width: 30%;
    justify-content: flex-start;
}

.right-30{
    display: flex;
    width: 30%;
    justify-content: flex-end;
}

.left-70{
    display: flex;
    width: 70%;
    justify-content: flex-start;
}

.right-70{
    display: flex;
    width: 70%;
    justify-content: flex-end;
}

.right-80{
    display: flex;
    width: 80%;
    justify-content: flex-end;
}

.logo img{
    height: 44px;
    cursor: pointer;
}

.header-pos svg{
    height: 24px;
    fill:#5f6368;
}

.header-pos svg:hover{
    /* height: 24px; */
    /* fill:rgba(110, 45, 144, 1); */
    fill:white;

}

.header-pos .search{
    margin:0px 15px;
}

/* .header  */
.img-btn{
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color:#F0F2F5;
    background-color:var(--secondary-btn-clr);
    height: 46px;
    width:46px;
    cursor: pointer;
    transition: all .5s;
}

.header-pos .left .img-btn{
    margin-right: 15px;
}

.header-pos .right .img-btn{
    margin-left: 15px;
}

/* .header  */
.img-btn:hover{
    /* background-color: #ffe6ff; */
    /* var(--primary-clr); */
    background-color:rgba(110, 45, 144, 1);
    background-color:var(--primary-clr);
    /* R-110 / G-45 / B-144 */
}

/* .header  */
.img-btn:hover svg{
    /* background-color: #ffe6ff; */
    /* rgba(110, 45, 144, 1); */
    fill:white;
    /* R-110 / G-45 / B-144 */
}

.header-pos .menu-overlay {
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    cursor: default;
}

.menu-left{
    height: 100%;
    /* width: 360px; */
    position: fixed;
    z-index: 3000;
    left: -360px;
    top: 0;
    
    transition: all .5s;
    display: flex;
}


/* z-index: 1000; */
    

.menu-right{
    height: 100%;
    /* width: 260px; */
    position: fixed;
    /* z-index: 3000; */
    z-index: 1000;
    right: -360px;
    top: 0;
    /* overflow: auto; */
    transition: all .5s;
    display: flex;
}

.menu-mobile-user{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px #d2d6de;
    border-bottom: solid 1px var(--btn-border-clr);
    padding: 10px;
    margin-bottom: 10px;
}

.menu-mobile-user .avatar-circle{
    align-self: auto;
    margin-bottom: 10px;
}

.menu-content{
    background-color: white;
    width: 260px;
    overflow: auto;
}

.menu-content::-webkit-scrollbar {
    width: 5px;
}

.menu-content::-webkit-scrollbar-thumb {
    /* background: #666;  */
    background: silver;
}

.menu-close-button{
}

.menu-logout-footer{
    position: fixed;
    bottom: 0;
    width: 260px;
    padding: 10px;
    text-align: center;
    background: white;
}


.menu-show-left{
    left: 0px;
}

.menu-show-right{
    right: 0px;
}

.menu-item{
    display: flex; 
    flex-direction: row; 
    flex-grow: 1; 
    align-items: center;
    padding: 10px;
    height: 48px;
    font-size:14px;
    cursor: pointer;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    margin-right: 5px;
    /* transition: all .5s; */
}

.menu-item-text{
    width: 85%;
}

.menu-item svg{
      height: 22px;
      margin-right: 10px;
}

.menu-item:hover{
background-color: #F0F2F5;
background-color: var(--secondary-btn-clr);
color: rgba(110, 45, 144, 1);
color: var(--primary-clr);
}

.menu-item:hover svg{
fill: rgba(110, 45, 144, 1);
fill: var(--primary-clr);
}

.menu-item-selected{
    background-color: #F0F2F5;
    background-color: var(--secondary-btn-clr);
    color: rgba(110, 45, 144, 1);
    color: var(--primary-clr);
}

.menu-item-selected-child-items{
    border-bottom-right-radius: 0px;
}

.menu-item-selected svg{
    /* fill: var(--primary-clr); */
    fill:rgba(110, 45, 144, 1);
    fill:var(--primary-clr);
}

.menu-item-child-container{
    background-color: #F0F2F5;
    background-color: var(--secondary-btn-clr);
    margin-right: 5px;
    border-bottom-right-radius: 32px;
}

/* .menu-item-child{
    display: flex; 
    flex-direction: row; 
    flex-grow: 1; 
    align-items: center;
    padding: 10px;
    height: 48px;
    font-size:14px;
    cursor: pointer;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    margin-right: 5px;
    transition: all .5s;
} */

.menu-item-child:hover{
background-color: rgba(110, 45, 144, 1);
background-color: var(--primary-clr);
/* background-color:rgba(241, 243, 244, 1);; */
color: white;
}

.menu-item-child:hover svg{
fill: white;
}




.menu-item-child-selected{
    /* background-color:rgba(241, 243, 244, 1);; */
    /* background-color: var(--secondary-btn-clr); */
    /* border-bottom-right-radius: 0px; */
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    background-color: rgba(110, 45, 144, 1);
    background-color: var(--primary-clr);
    color: white;
    /* color: var(--primary-clr); */
}

.menu-item-child-selected svg{
/* fill: var(--primary-clr); */
fill: white;

}



.menu-logo{
text-align: center;
padding: 10px;
border-bottom: solid 1px rgba(241, 243, 244, 1);
margin-bottom: 5px;
/* cursor: pointer; */
}

.menu-logo img{
height: 46px;
}

.menu-search{
    margin-left:15px;
    margin-right:  10px;
}

.menu-user{
    padding:10px;
    text-align: center;
    margin: auto
}

.menu-user .avatar-circle{
    margin: auto;
    margin-bottom: 10px;

}
.store-selection-list{
    height: 400px;
    overflow-y: auto;
}

.store-selection-list .list-row{
padding: 10px;
margin: 10px 0px;
cursor: pointer;
}

.store-selection-list .list-row:hover{
    background-color:  rgba(241, 243, 244, 1);
    border-radius: 10px;
}

.store-selection-list .row-title{
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 5px;
    cursor: pointer;
}

.store-selection-list .row-sub-text {
    font-size: 14px;
    cursor: pointer;
}

/* Input Start */
.input-rounded{
    border-radius: 32px;
    box-sizing: border-box;
    z-index: 100;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 46px;
    background-color: #F0F2F5;
    background-color: var(--secondary-btn-clr)
    /* background-color: rgba(241, 243, 244, 1); */
}

.input-rounded span {
    margin-top: 8px;
    margin-right: 10px;
}

.input-rounded svg{

}

.input-rounded input {
    padding: 6px;
    padding-left: 28px;
    width: 100%;
    background-color: transparent;
    resize: none !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    border-radius: 32px;
}

.input-left-rounded{
    border-top-left-radius: 35px;
    border-bottom-left-radius:35px;
}

/* Input End */

/* Button start */

.button-primary{
    /* border-radius: 32px; */
    width: 100%;
    height: 46px;
    background-color:rgba(110, 45, 144, 1);
    background-color:var(--primary-clr);
    border:none;
    color: white;
    margin-bottom: 10px;
    display: flex;
    justify-content: center
}

.button-primary svg{
    margin: 10px 0px;
    fill:white;
}

.button-primary:hover{
    color:rgba(110, 45, 144, 1);
    color:var(--primary-clr);
    border:solid 1px rgba(110, 45, 144, 1);
    border:solid 1px var(--primary-clr);
    background-color: white;
}

.button-primary:hover svg{
    fill:rgba(110, 45, 144, 1);
    fill:var(--primary-clr);
}

.button-processing{
    color:rgba(110, 45, 144, 1);
    color:var(--primary-clr);
    border:solid 1px rgba(110, 45, 144, 1);
    border:solid 1px var(--primary-clr);
    background-color: white;
}

.button-secondary{
    /* border-radius: 32px; */
    width: 100%;
    height: 46px;
    background-color:#F0F2F5;
    background-color:var(--secondary-btn-clr);
    border:solid 1px #F0F2F5;
    border:solid 1px var(--secondary-btn-clr);
    margin-bottom: 10px;
    display: flex;
    justify-content: center
}

.button-secondary svg{
    margin: 10px;
}

.button-secondary:hover{
    color:rgba(110, 45, 144, 1);
    color:var(--primary-clr);
    border:solid 1px rgba(110, 45, 144, 1);
    border:solid 1px var(--primary-clr);
    background-color: white;
}

.button-secondary div, .button-primary div, .button-secondary i, .button-primary i{
    margin: 12px 0px;
}

.button-selected {
    background-color: rgba(110, 45, 144, 1);
    background-color: var(--primary-clr);
    color:white;
    border-color: rgba(110, 45, 144, 1);
    border-color: var(--primary-clr);
}

.button-selected svg{
    fill:white;
}

.button-link{
    height: 46px;
    width: 100%;
    cursor: pointer;
}

.button-rounded{
    border-radius: 32px;
}

.button-currency{
    border-radius: 10px;
    background-color: #F0F2F5;
    background-color: var(--secondary-btn-clr);
    height: 70px;
    width:70px;
    margin-right: 10px;
    border:solid 1px #d2d6de;
    border:solid 1px var(--btn-border-clr);
    margin-bottom: 10px;
}

.button-currency:hover{
   border-color:rgba(110, 45, 144, 1);
   border-color:var(--primary-clr);
   color:rgba(110, 45, 144, 1);
   color:var(--primary-clr);
   background-color: white;
}

#bootstrap-overrides .button-lg{
    height:46px;
}


#bootstrap-overrides .button-flat{
    border-radius: 0px;
    background: #F0F2F5;
    background: var(--secondary-btn-clr);

}


#bootstrap-overrides .button-left-rounded{
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    background: #F0F2F5;
    background: var(--secondary-btn-clr);

}

#bootstrap-overrides .button-right-rounded{
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    background: #F0F2F5;
    background: var(--secondary-btn-clr);

}

#bootstrap-overrides .button-border{
    border:solid 1px #d2d6de;
    border:solid 1px var(--btn-border-clr);
}

#bootstrap-overrides .button-selected {
    background-color: rgba(110, 45, 144, 1);
    background-color: var(--primary-clr);
    color:white;
    border-color: rgba(110, 45, 144, 1);
    border-color: var(--primary-clr);
}

#bootstrap-overrides .button-selected svg{
    fill:white;
}

/* Button end */




.button-KOT{
    height: 66px;
}

.buttton-printbill{
    height: 66px;
}







.pay-btn {
    height: 60px;
    padding: 10px;
    background-color: rgba(110, 45, 144, 1);
    background-color: var(--primary-clr);
    color:white;
    padding:0px 32px;
    border-radius: 32px;
    border:none;
    width: 100%;
    font-size: 20px;
    transition: all .5s;
    cursor: pointer;
}

.sales-return{
    background-color: orange;
}


.sales-edit {
    background-color: blue;
}

.pay-btn:hover {
    color: rgba(110, 45, 144, 1);
    color: var(--primary-clr);
    background-color:white;
    border: solid 1px rgba(110, 45, 144, 1);
    border: solid 1px var(--primary-clr);
    cursor: pointer;
}

.pay-btn-return{
    height: 46px;
    padding: 10px;
    background-color: rgba(110, 45, 144, 1);
    background-color: var(--primary-clr);
    color:white;
    padding:0px 32px;
    border-radius: 32px;
    border:none;
    width: 100%;
    font-size: 20px;
    transition: all .5s;
    cursor: pointer;
}

.pay-btn-return:hover {
    color: rgba(110, 45, 144, 1);
    color: var(--primary-clr);
    background-color:white;
    border: solid 1px rgba(110, 45, 144, 1);
    border: solid 1px var(--primary-clr);
    cursor: pointer;
}

.sales-footer{
    height: 80px; 
    background-color: white; 
    padding: 10px 10px 10px 10px; 
    position: fixed; 
    left: 0px; 
    bottom: 0px; 
    /* border-top: 1px solid rgb(221, 221, 221);  */
    width: 100%;
}

.sales-footer-options{
    display:flex;
}

.sales-footer-option-left{
    display: flex;
    width: 50%;
    justify-content: flex-start;
}

.sales-footer-option-right{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}

/* .sales-footer-option-left .img-btn{
    margin-right: 15px;
} */

/* .sales-footer-option-right .img-btn{
    margin-left: 15px;
} */

.sales-footer-btn{
    width: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sales-footer-btn-label{
    font-size: 10px
}

.rbt-input-main{
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
}



/* .btn-flat{
    background: var(--secondary-btn-clr);
    border-top:solid 1px var(--btn-border-clr);
    border-bottom:solid 1px var(--btn-border-clr);    
    border-left: none;
    border-right: none;
    height: 46px;
} */

#bootstrap-overrides .btn-nocurve{
        border-radius: 0px !important;
        border: 1px solid #ccc !important;
    }

#bootstrap-overrides .btn-nocurve:hover{
    border-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .btn-left-nocurve{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .btn-left-nocurve:hover{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .btn-left-nocurve{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .btn-right-nocurve:hover{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .small{
    height: 30px;
    min-width: 30px;
    padding-top: 2px;
}

.sales-prodcut-search-section {
    display: flex;
}
.sales-qty{
    height: 30px;
    width: 66px;
    border:solid 1px #d2d6de;
    border:solid 1px var(--btn-border-clr);
    text-align: center;
    
}

/* .sales-remove{
    margin-top: 20px;
} */

.sales-remove svg{
    fill:rgb(153, 153, 153);
    margin-top:22px;
    cursor: pointer;
}

.sales-lt{
    padding-top:5px;
}

.info{
    background: #F0F2F5;
    background: var(--secondary-btn-clr);
    border-radius: 10px;
    padding:10px;
    text-align: left;
    margin-bottom: 10px;
}

.info-header{
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px;
    /* border-bottom: solid 1px var(--btn-border-clr) */
}


/* quick add start */

.quick-add-container{
    display:flex;
    position: fixed;
    width: 500px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5000;
}

.quick-add{
    background-color:white; 
    border-radius: 10px;
    padding:10px;
    width: 100%;
}
.quick-add-close-button{
    z-index: 5000;
}
.quick-add-header{
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.quick-add-content{
    display: flex;
}

.quick-add-options{
    display: flex;
    flex-flow: column;
    padding-right: 10px;
    width: 50%;
    overflow: auto;
    height: 450px;
}

.quick-add-options::-webkit-scrollbar {
    width: 5px;
}

.quick-add-options::-webkit-scrollbar-thumb {
    /* background: #666;  */
    background: silver;
}

.quick-add-options-header{
    padding:10px;
    font-weight: bold;
}
/* quick add end */




/* sales payment start */

/* .style={{ overflowX: "scroll", overflow: "scroll", height: "80vh" }} */
.sales-payment-close-button{
    top: 10px;
    position: absolute;
    z-index: 500;
    right: 15px;
}

.sales-payment{
    border-radius: 10px;
    background: white;
    padding: 10px 0px;
    position: absolute;
    z-index: 600;
    top:50px;
    margin:10px 20px 0px 20px;
    padding-top:20px;
    transition: all .5s;
}

.payment-left-section{
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.add-payment{

}
/* style={{ marginTop: "5px", marginLeft: "15px" }} */
.cash-payment-mode{
    margin-top:10px;
    margin-left:15px;
}

.payment-right-section{
    min-height: 60vh;
    margin-right: 15px;
    /* margin-top:15px; */
}

/* style={{ marginTop: "20px", marginBottom: "20px" }} */
/* style={{ fontSize: "35px" }} */
.payment-right-section-header label{
    font-size: 30px;
    padding-top:15px;
    padding-bottom:20px;
}

.payment-right-section-paymentmode{
    display:flex;
    padding-bottom: 15px;
}

.payment-right-section-paymentmode label{
    font-size: 18px;
    padding-top:15px;
    padding-left: 15px;
}

.payment-right-section-paid-amt {
    padding-bottom: 10px;
}


.payment-right-section-paid-amt label{
    font-size: 18px;
}

.payment-right-section-paid-amt .left label{
    padding-top: 10px;
}

.payment-right-section-bal-amt{
    padding-bottom: 10px;
}

.payment-right-section-bal-amt label{
    font-size: 18px;
}
/* style={{ display: "flex", justifyContent:"spaceBetween" }} */
.payment-right-section-due-date{
    display: none;
}
/* sales payment end */

/* Login start */
.login{
    width:100%;
    height: 100vh;
    /* background:#F7F7F7; */

    /* background-image:linear-gradient(to right, rgb(110, 45, 144) 0,rgb(110, 45, 144) 33%,rgb(144,39,142) 33%, rgb(144,39,142) 66%, rgb(236,28,36) 66% , rgb(236,28,36)100%);; */
    /* background-image:linear-gradient(to right, rgb(110, 45, 144) , rgb(144,39,142),rgb(144,39,142), rgb(144,39,142),rgb(236,28,36));; */
}

.login-logo{
    padding-top: 30px;padding-bottom: 40px;text-align: center;
}

.align-center{
    text-align: center;
}

.signup-logo
{
text-align: center;
width: 100%;
}

.signup-header{
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;

}

.signup-container{ 
    width: 350px;
    background-color: white;
    border-radius:10px;
    padding: 20px;
    margin: auto;
}

.signup-container-mobile{
    width: 350px;
    background-color: white;
    border-radius:10px;
    padding: 20px;
    margin: auto;
}

.login-container{ 
    width: 350px;
    background-color: white;
    border-radius:10px;
    padding: 20px;
    margin: auto;
    border:solid 1px #d2d6de;
    border:solid 1px var(--btn-border-clr) ;
    text-align: center
}

.login-container-mobile{
    width: 350px;
    background-color: white;
    border-radius:10px;
    padding: 20px;
    margin: auto;
    /* border:solid 1px var(--btn-border-clr) ; */
    text-align: center;
}

.login-container-mobile img{
    margin: 30px 0px 40px 0px;

} 

.login-container img{
    
    margin: 30px 0px 40px 0px;
}

/* Login end */





.right-flat{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

}

/* inventory start */

/* .inventory-button-bar{
padding-bottom: 10px;
} */

/* inventory end */

#bootstrap-overrides .invoice{
    margin: 0px;
    background: white;
    padding: 10px;
    border-radius: 10px;
}

.account-expired{
    width: 100%;
    background-color:red;
}

.account-expiring{
    width: 100%;
    background-color: orangered;
    border-radius: 10px;
    /* margin: -20px; */
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    color: white;
    text-align: center;
}


/* Customer dashboard start*/
.customer-info-section{
    display: flex;
}

.customer-info-section .info-box{
    background: white;
    border-radius: 10px;
    padding:10px;
    display: flex;
}
/* Customer dashboard end */


/* Report dashboard start*/
.dashboard-header{
    display: flex;
    margin-bottom: 10px;
}

.dashboard-header-title{
    font-size: 32px;
}
.dashboard-header-options{
    display: flex;
    /* justify-content: end; */
}

.dashboard-header-options .input-ctrl .form-control{
    height:46px;
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
}

.dashboard-summary{
    display: flex;
    margin-top:20px;
    flex-wrap: wrap;

    /* justify-content: space-between; */
}

.info-box{
    display: flex;
    padding:20px;
    border-radius: 10px;
    /* width: 250px; */
    /* margin-right: 20px; */
    margin-bottom: 20px;
}

.info-box-icon{

    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.18);
    margin-right: 20px;
}

.info-box-icon svg{
    fill: white;
    margin-top: 11px;
    margin-left: 14px;
}

.info-box-content{

}

.info-box-text{
    color: white;
    font-size: 18px;
}

.info-box-number{
    color: white;
    font-size: 24px;
}

.info-box-number-subtext{
    color: white;
    font-size: 14px;
}

.info-blue{
    box-shadow: 0 6px 20px 0 rgba(38,198,218,.5)!important;
    background: linear-gradient(45deg,#0288d1,#26c6da)!important;
}
.info-red{
    background: linear-gradient(45deg,#ff5252,#f48fb1)!important;
    box-shadow: 0 6px 20px 0 rgba(244,143,177,.5)!important;
}
.info-yellow{
    box-shadow: 0 6px 20px 0 rgba(255,202,40,.5)!important;
    background: linear-gradient(45deg,#ff6f00,#ffca28)!important;
}
.info-green{
    background: linear-gradient(45deg,#43a047,#1de9b6)!important;
    box-shadow: 0 6px 20px 0 rgba(29,233,182,.5)!important;
}

.blue{
   
    background-color: rgb(38,198,218);
}

.red{
    background-color:#ff5252;

}
.orange-red{
    background-color:#ff6f00;
}
.green{
    background-color:#43a047;
}

.dashboard-details{
    display: flex;
    margin-top:20px;
    flex-wrap:wrap;
}

.dashboard-box{
    background: white;
    border-radius: 10px;
    padding:10px;
    margin-bottom: 10px;
    height: 450px;
    /* margin-right: 1%; */
    /* flex: 32%; */
    /* max-width: 32%; */
    
}

.dashboard-box-wide{
    background: white;
    border-radius: 10px;
    padding:10px;
    margin-right: 1%;
    /* margin-right: 20px;
    margin-bottom: 20px; */
    /* width:66%; */
    margin-bottom: 10px;
    flex: 65% 1;
  max-width: 65%;
  /* padding: 0 4px; */
}

.dashboard-box-header{
    display: flex;
    font-size: 18px;
    margin-bottom: 10px;
}
.dashboard-box-body{
    height: 300px;
}
.dashboard-box-footer{
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.chart{
    height:250px;
}

.table-responsive{
    height: 310px
}
/* Report dashboard end */


@media print
{

    .no-print
    {
        display: none !important;
        height: 0;
    }


    .no-print, .no-print *{
        display: none !important;
        height: 0;
    }
}


.customer-dashboard-details{
    background: white;
    padding: 10px;
    border-radius: 10px;
    /* margin-top:20px; */
}

.customer-dashboard-details ul{
    margin-bottom: 10px;
}

.customer-dashboard-details-tab-section{
    height: 330px;
    padding: 10px;
}
/* 
.box-body{
    position: absolute;
    top:100px;
    z-index: 6000;
} */


.header-margin{
    margin-top: 76px;
}



/* Input control start */

.input-ctrl{
    margin-bottom: 10px;
}

.textarea{
    border-radius: 10px;
}
/* Input control end */


.number{
    text-align: right;
}

.mailbox-attachment-icon img{
    height: 50px;
}
.pincode-product-images{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    
}

.pincode-product-image{
    border: solid 2px #F0F2F5;
    border: solid 2px var(--secondary-btn-clr);;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative; 
}

.pincode-product-image img{
    height: 200px;
}

.pincode-product-image-upload{
    height: 200px;
}

.pincode-product-image-img {
    flex: 1 1; /* Allow the image div to take up available space */
    display: flex; /* Use flexbox for the image */
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
}

.pincode-product-image-remove {
    position: absolute; /* Position it absolutely within the parent */
    bottom: 10px; /* Distance from the bottom */
    right: 10px; /* Distance from the right */
    
}

.box-header label{
    font-size: 20px;
}

.report-line-item{
    margin: 10px 0px;
    padding-bottom: 10px;
    width: 100%;
    border-bottom: dashed 1px #5f6368;
}

.inventory-footer{
    height: 60px;position: fixed;bottom: 0px;width:100%;right:0;background-color: #F0F2F5;left:0;
}

.inventory-footer-sub{
    background-color: white;
    margin-left: 15px;
    margin-right: 15px;
    border-top: 1px solid rgb(221, 221, 221);
    display: flex;
    padding: 5px 10px;
    /* padding-left: 10px;
    padding-right: 10px; */
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

}

.inventory-footer-sub-50{
    width:50%;
}




.timePicker{
    display: flex;
    border: solid 1px #d2d6de;
    border: solid 1px var(--btn-border-clr);
    border-radius: 32px;
}

.timePicker input{
    border:none;
    border-radius: none;
}

.timePicker select{
    border:none;
    border-radius: none;
    width: 100px;
}

.timePicker svg{
    margin: 5px 5px 0px 5px;
}

.timePicker .seperator{
    margin-top: 4px;
    font-weight: bold;
    font-size: 18px;
}

.timePicker .input-group{
    border: solid 1px #d2d6de;
    border: solid 1px var(--btn-border-clr);
    border-radius: 32px;
    background: #F0F2F5;
    background: var(--secondary-btn-clr);
}

.uppercase{
    text-transform: uppercase;
}

.header-online-switch{
    align-items: center;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #F0F2F5;
    background-color: var(--secondary-btn-clr);
    height: 46px;
    cursor: pointer;
    padding-top:5px;
    padding-right: 10px;
    padding-left: 10px;
    width: 134px;

}

.header-online-switch span{
    margin-right: 5px;
    text-transform: uppercase;
}
.header-online-switch .input-ctrl{
    margin-bottom: 0px;
}
/* Custom properties */
:root {
    --tooltip-text-color: white;
    --tooltip-background-color: rgba(110, 45, 144, 1);
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
  }
  
  /* Wrapping */
  .Tooltip-Wrapper {
    display: inline-block;
    position: relative;
  }
  
  /* Absolute positioning */
  .Tooltip-Tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: white;
    color: var(--tooltip-text-color);
    background: rgba(110, 45, 144, 1);
    background: var(--tooltip-background-color);
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;
  }
  
  /* CSS border triangles */
  .Tooltip-Tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(6px * -1);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.top {
    top: calc(30px * -1);
    top: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: rgba(110, 45, 144, 1);
    border-top-color: var(--tooltip-background-color);
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.right {
    left: calc(100% + 30px);
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.right::before {
    left: calc(6px * -1);
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: rgba(110, 45, 144, 1);
    border-right-color: var(--tooltip-background-color);
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.bottom {
    bottom: calc(30px * -1);
    bottom: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: rgba(110, 45, 144, 1);
    border-bottom-color: var(--tooltip-background-color);
  }
  
  /* Absolute positioning */
  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + 30px);
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(6px * -2);
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: rgba(110, 45, 144, 1);
    border-left-color: var(--tooltip-background-color);
  }
  
.typeAheadSelect-serachitem{
    border-bottom: solid 1px var(--btn-border-clr);
    margin-left: -10px;
    padding: 10px;
    margin-right: -10px;
}

.typeAheadSelect-serachitem .maintext{
    font-weight: bold;
    font-size: 18px;

}

.typeAheadSelect-serachitem .subtext{
    /* font-size: 10px; */
}

.typeAheadSelect-selected{
    display: flex;
    border-radius: 35px;
    background: var(--secondary-btn-clr);
    border: solid 1px var(--btn-border-clr);
}

.typeAheadSelect-selected .maintext{
    padding-left:20px; 
}

.typeAheadSelect-selected .subtext{
    font-size: 10px;
    padding-left:20px;
}


.otp input{
    border: 1px solid #ccc;
    width: 40px !important;
    height: 34px !important;
    border-radius: 10px;
}

.otp span{
    margin: 5px;
}

.input-with-suffix {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .input-with-suffix .form-control {
    padding-right: 2.5rem; /* Adjust based on the size of the suffix */
  }
  
  .input-with-suffix .input-suffix {
    position: absolute;
    right: 0.5rem;
    color: #6c757d; /* Adjust for the desired color */
    pointer-events: none;
  }
.spinner-container{
    /* width:100%; */
    /* height:50vh; */
    /* text-align: center;
    padding-top:40vh; */

    height: 100%;
    width: 100%;
    position: absolute;
    text-align: center;
    z-index: 1;
    top: 0;
    left: 0;
    
    overflow-x: hidden;
    z-index: 100;
}

.spinner-container-overlay{
  background: rgba(103, 118, 140, 0.5);
}
.spinner-sub-container{
    padding-top:40vh;
}

.spinner {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid var(--primary-clr);
    width: 50px;
    height: 50px; /* Safari */
    animation: spin 2s linear infinite;
    display: inline-block
  }
  
  /* Safari */
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

/* 
h1, h2, h3, h4, h5 {
    font-family: 'Roboto';
} */

/* .sidebar-mini.sidebar-collapse .content-wrapper
{
    margin-left: 0px !important;
} */

.header-new-background{
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: -1;
}

.white-backround
{
    background-color: white;
}

.menubar {
    overflow: hidden;
    background-color: #00a65a;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    height: 56px;
    padding: 0px 10px;
    z-index:1000;
  }

  .menubar-logo
{
    width: 100%;
    text-align: center;
}

.menubar-logo img{
    width:80px;
    margin-top:6px;
}

.menubar-button{
    top: 0px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 10px;
    width: 40px;
    height: 100%;
}

.menubar-button-back{
    top: 0px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 10px;
    width: 40px;
    height: 100%;
}

  .menubar-title
  {
    color:white;
    font-size:17px;
    margin-top:15px;
    width: 100%;
    text-align: center;
    }

  .menubar-button-filter{
    top: 18px;
    position: absolute;
    right: 10px;
    color: white;
  }

  .menubar-button-edit{
    top: 16px;
    position: absolute;
    right: 10px;
    color: white;
  }

  .menubar-button-delete{
    padding-top:10px;
    padding-left:10px;
    padding-right:10px;
    color: white;
  }

  .menu-overlay {
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;
    cursor: default;
  }

  .menu{
    height: 100%;
    width: 80%;
    position: fixed;
    z-index: 3000;
    left: 0;
    top: 0;
    padding:20px 20px;
    background-color: white;
    overflow: auto;
    max-width: 300px;
    }

  

  .menu-row{
    display: flex; 
    flex-direction: row; 
    flex-grow: 1; 
    align-items: center;
    padding: 16px 0px;
  }

  .menu-text{
    font-size: 17px;
    /* font-weight:bold; */
    font-weight: 500;
    width:90%;
    padding-left:20px;
    padding-right:20px;
    color: #101928;
  }

  .menu-icon{
    align-self: flex-start;
    padding-top:5px;
}

  .menu-text-header{
    font-size: 17px;
    /* font-weight:bold; */
    font-weight: 500;
    width:80%;
    text-align:center;
    color: #101928;
  }

  .menu-sub-text
  {
    font-size:12px;
    padding-top:5px
  }

.menu-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 80%;
    padding:10px;
    background-color: white;
    text-align: center;
    max-width: 300px;
    z-index: 3001;

}

.menu-signout{
     
    height:46px;
    border:none;
    border-radius: 50px;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
    color: #101928;
    background-color:#f7f7f8;
    outline-style: none;
  }

  /* .content-wrapper{
      padding-top: 56px;
  } */
  
.container{
    padding: 0px 10px;
    background: white;
}

.row-title{
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
}

.row-sub-text{
    font-size: 13px;
}

.outstanding{
    margin-top: 5px;
    margin-right: 5px;
    font-size: 13px;
    background-color: #dd4b39 !important;
    /* display: inline; */
    padding: .2em .6em .3em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.wallet{
    margin-top: 5px;
    margin-right: 5px;
    font-size: 13px;
    background-color: #00a65a !important;
    /* display: inline; */
    padding: .2em .6em .3em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.membership{
    margin-top: 5px;
    margin-right: 5px;
    font-size: 13px;
    background-color: #f39c12 !important;
    /* display: inline; */
    padding: .2em .6em .3em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.loyalty{
    margin-top: 5px;
    margin-right: 5px;
    font-size: 13px;
    background-color: #f39c12 !important;
    /* display: inline; */
    padding: .2em .6em .3em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}


.list{
    height: 100%;
    padding-top: 60px;
}

.list-row{
    display: flex; 
    flex-direction: row; 
    flex-grow: 1; 
    align-items: center;
    padding: 16px 0px;
    
}

.list-image{
  width: 60px;
  margin-right: 20px;
}

.seperator{
    border-bottom: 1px solid #dee3e7;
}

.text1{
    font-weight: 700;
    font-size: 28px;
    color: #101928;
    line-height: 35px;
}

.field{
    font-size: 13px;
    color: #101928;
    font-weight: 400;
    line-height: 16px;

    word-wrap: break-word;
    max-width: 100%;
    min-width: 0;

    padding-top:20px;
}

.value{
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #101928;

    word-wrap: break-word;
    max-width: 100%;
    min-width: 0;

    padding-bottom:20px;
}

.address{
    padding-top:20px;
    padding-bottom:20px;
    border-bottom: 1px solid #dee3e7;
}

.address-main{
    font-size: 17px;
    color: #101928;
    font-weight: 400;
    padding-bottom:5px;
}

.address-main i{
    padding-right:5px;
}
.address-sub{
    font-size: 13px;
    color: #101928;
}

label{
    font-size: 13px;
    font-weight: 400;
    color: #101928;
    line-height: 16px;
}


.login-border-radius
{
    border-radius: 25px;
}

.login-button
{
    background-color: #00a65a;
    height: 46px;
    width:100%;
    border: none;
    color: white;
    outline-style: none;
}

/* .login-box
{
    text-align: center;
} */

.border-radius
{
    border-radius: 50px;
}

.home-sms
{
    width: 100%;
    background-color: white;
}

.no-top-padding
{
    padding-top: 0px
}

.selectRounded{
    border-radius:36px;
    display:inline-block;
    overflow:hidden;
    background:#cccccc;
    border:1px solid #cccccc;
  }

  .quick-add-button{
    width: 100%;
    border: none;
    border-radius: 50px;
    box-shadow: none;
    text-transform: none;
    font-size: 18px;
    font-weight: 500;
    /* color: white; */
    /* background-color: #f74969; */
    height: 44px;
    outline: none;
  }
.list-search{  
    background-color: white;
    position: fixed;
    padding:10px;
    top: 65px;
    left: 0;
    right: 0;
    z-index:99;
}

.list-search-div{
    border: 1px solid #dee3e7;
    border-radius: 32px;
    border-width: 1px;
    box-sizing: border-box;
    padding:8px;
    z-index:100;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.list-search-div input{
    padding: 6px;
    padding-left: 28px;
    width: 100%;
    background-color: transparent;
    resize: none !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border: none;
    outline: none;
    -webkit-appearance: none;
}

.list-search-div span{
    width: 16px;
    height: 16px;
    color: lightgray;
}

.list-search-div i{
    color: lightgray;
}
/* window start*/
.window-overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;
    cursor: default;
}

.window-header-borderless-mobile{
    position: absolute;
    top:0px;
    left: 0px;
    padding:10px;
    font-size: 20px;
    z-index: 6000;
}

.window-content-borderless{
    padding: 10px;
    overflow: auto;
    max-height: 80vh;
}

.window-content-borderless-mobile{
    margin-top:-10px;
    padding: 10px;
    overflow: auto;
    height: 100%;
    position: fixed;
    width: 100%;

}

.window-footer-mobile{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: white;
    /* color: white; */
    text-align: center;
}

.window-footer-mobile button{
    width: 100%;
    /* margin:10px; */
}

.window{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5000;
}

.window-mobile{
    position: fixed; /* Stay in place */
    z-index: 5000; /* Sit on top */
    left: 0;
    top: 0;
    background-color:white; 
}

.window-container{
    background-color:white; 
    border-radius: 10px;   
}

.window-container-mobile{   
}

.window-container .margin{
    margin-right: 46px;
}

.window-header{
    width: 100%;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    border-bottom: 1px solid #dee3e7;
    background-color: white;
    padding:10px;
    font-size: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.window-header-mobile{
    width: 100%;
    display: flex;
    border-bottom: 1px solid #dee3e7;
    background-color: white;
    z-index: 5001;
    padding:10px;
    font-size: 20px;
}

.window-close-btn{
    position: absolute;
    right: -48px;
}

.window-close-btn svg{
    fill:black;
    /* width: 12px; */
}

.window-content{
    padding: 10px;
    overflow: auto;
    max-height: 80vh
}

.window-content-mobile{
    padding: 10px;
    overflow: auto;
    height: 100%;
    width: 100%;
}

.window-footer{
    display: flex;
    width: 100%;
    padding: 10px 10px 0px 10px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.window-footer-mobile{
    display: flex;
    width: 100%;
    padding: 10px 10px 0px 10px;
    background-color: white;
    position: fixed;
    bottom: 0px;
}

.window-footer button{
    width: 100%;
    /* margin:10px; */
}

.address-action-button{
    padding-top: 10px;
}

.address-action-button a{
    padding: 10px 50px 10px 0px;
}

.window-mobile-header-title{
    font-size: 20px;
    padding-top: 10px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 46px;
}

.window-bg-color{
    background-color: var(--secondary-btn-clr);
}
/* window end */


.invoicedetail-image {
    height: 110px;
    width: 110px;
    -o-object-fit: cover;
       object-fit: cover;
}
.WidgetPincodeProductDashboard-box {
    background: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.WidgetPincodeProductDashboard-box-header {
    display: flex;
    font-size: 18px;
    margin-bottom: 10px;
}

.WidgetPincodeProductDashboard-box-title {

}
/* form start */
.form-header{
    padding: 10px;
    text-align: center;
    border-bottom: solid 1px var(--btn-border-clr);
    height: 46px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.form-content{
    background-color: white;
    width: 500px;
    overflow: auto;
    padding: 10px;
    /* height: 80vh; */
}

.form-footer{
   display: flex;
   position: fixed;
   bottom: 0;
   width: 500px;
   padding:10px 10px 0px 10px;
   border-top: solid 1px var(--btn-border-clr);
   background: white;
}
/* form end */

/* form mobile start */

.form-mobile{
    z-index: 400;
    position: absolute;
    background-color: white;
    width: 100%;
    top: 0;
}

.form-mobile-header{
    overflow: hidden;
    
    position: fixed;
    top: 0;
    width: 100%;
    /* height: 56px; */
    padding: 10px;
    display: flex;
    border-bottom: solid 1px var(--secondary-btn-clr);
    background-color: white;
}

.form-mobile-header-title{
    font-size: 20px;
    /* padding-top: 10px; */
    padding-left: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.form-mobile-content{
    padding-top: 67px;
    margin:10px;
}

.form-mobile-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    z-index: 2;
    padding: 10px;
    border-top: solid 1px var(--secondary-btn-clr);
}

/* form mobile end */
.form-section{
    /* padding-top: 20px; */
    /* padding-bottom: 10px; */
    text-align: left;
    /* border-bottom: solid 1px var(--btn-border-clr); */
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    /* color: var(--primary-clr) */
}

.form-section-content{
    background-color: white;
    margin-top: 10px;
    padding-bottom: 20px;
    /* border-bottom: solid 1px var(--btn-border-clr); */
}
.record-not-found{
    width: 100%;
    text-align: center;
}

.record-not-found svg{
    height: 60px !important;
}

.record-not-found-title
{
    font-weight: 700;
    font-size: 22px;
    color: #101928;
    line-height: 25px;
    margin-bottom: 16px;
}

.record-not-found-text{
    color: #67768c;
    font-size: 16px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 16px;
}
.avatar-circle {
    width: 60px;
    height: 60px;
    background-color: #f7f7f8;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;


    /* new */
    align-self: flex-start;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .bg{
    width: 128px;
    height: 128px;
  }

  .initials {
    /* position: relative; */
    /* top: 5px;  */
    font-size: 25px; /* 50% of parent */
    line-height: 60px; /* 50% of parent */
    color:#101928;
    /* font-weight: bold; */
  }

 .bg{
    font-size: 40px; /* 50% of parent */
    line-height: 128px; /* 50% of parent */
  }

  .avatar-user {
    width: 40px;
    height: 40px;
    background-color: #f7f7f8;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;


    /* new */
    align-self: flex-start;
    /* margin-right: 16px; */
    flex-shrink: 0;
    margin-top: -7px;
    
  }


  .initials-user {
    /* position: relative; */
    /* top: 5px;  */
    font-size: 25px; /* 50% of parent */
    line-height: 43px; /* 50% of parent */
    color:#00a65a;
    /* font-weight: bold; */
  }

/* .user-menu{
    right:15px;
    top:55px;
    width: 100%; 
    position: fixed;
    z-index: 3000;
    padding:20px 20px;
    background-color: white;
    max-width: 300px;
} */
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    z-index: 2;
    padding:10px;
 }

 .footer-button-save
 {
     width:100%;
     height:46px;
     border-radius: 50px;
     background-color:var(--primary-clr);
     color: white;
     font-size: 17px;
     border: none;
     box-shadow: none;
     outline: none;
 }

 .footer-button-primary
 {
  width:100%;
  height:46px;
  border-radius: 50px;
  background-color: var(--primary-clr);
  color: white;
  font-size: 17px;
  border: none;
  outline-style: none;
  box-shadow: none;
  outline: none;
 }


 .footer-button-secondary
 {
  width:100%;
  height:46px;
  border-radius: 50px;
  background-color:#f7f7f8;
  color: #101928;
  font-size: 17px;
  border: none;
  outline-style: none;
  box-shadow: none;
  outline: none;
 }

.list-page-filter-overlay {
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    cursor: default;
}

.list-page-filter{
    height: 100%;
    position: fixed;
    z-index: 1000;
    right: -460px;
    top: 0;
    transition: all .5s;
    display: flex;
}

.list-page-filter-show{
    right: 0px;
}

.list-page-filter-container{
    background-color: white;
    /* width: 360px; */
    width: 100%;
    overflow: auto;
   
}

.list-page-filter-header{
    padding: 10px;
    text-align: center;
    border-bottom: solid 1px var(--btn-border-clr);
    height: 46px;
    font-size: 18px;
}

.list-page-filter-content{
    background-color: white;
    /* width: 360px; */
    width: 100%;
    overflow: auto;
    padding: 10px;
    /* height: 80vh; */
}

.list-page-filter-footer{
   display: flex;
   /* position: fixed; */
   bottom: 0;
   /* width: 360px; */
   width: 100%;
   padding:10px 10px 0px 10px;
   border-top: solid 1px var(--btn-border-clr);
}

.list-page-filter-close-button{
    
}

/* List Page start*/
.list-page{
    background:white;
    border-radius: 10px;
    /* width:100%; */
    margin:10px;
}

.list-page-image{
    height: 150px;
    width: 150px;
    -o-object-fit: contain;
       object-fit: contain;
    /* object-fit: cover; */
}

.list-page-color{
    height: 50px;
    width: 50px;
}

.list-page-header{
    padding:10px !important;
    display: flex;
    height: 66px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.list-page-header-title{
    font-size: 28px;
    padding-top:5px;
}

.list-page-header .right .button-primary , 
.list-page-header .right .button-primary ,
.list-page-header .right .button-secondary , 
.list-page-header .right .button-secondary 
{
    margin-left: 5px;
    width:100px;
    margin-bottom: 0px;
}
.list-page-header .right .img-btn  {
    margin-left: 5px;
   
    /* width:120px; */
}

.list-page-search{
    display: flex;
    height: 46px;
    border-radius: 32px;
    border: solid 1px var(--btn-border-clr);
    background: var(--secondary-btn-clr);
    /* width: 400px;
    margin-left: 15px; */
}

.list-page-search input{
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    height: auto;
    border:none;
    width: 250px;
}

.list-page-search-btn-grp{
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    display: flex;
    /* background: white; */
}

.list-page-search-btn-grp .img-btn{
    height: 44px;
    width:44px;
}

.list-page-search-btn-search{
    text-align: center;
    padding: 12px 20px;
    /* border-right: solid 1px var(--btn-border-clr);
    border-left: solid 1px var(--btn-border-clr); */
    /* border-top-left-radius: 32px;
    border-bottom-left-radius: 32px; */
    background: var(--secondary-btn-clr);
    /* width: 90px; */
    cursor: pointer;
}

.list-page-search-btn-clear{
    border-top-right-radius: 32px; 
    border-bottom-right-radius: 32px;
    text-align: center;
    padding: 12px 20px;
    /* width: 90px; */
    background: var(--secondary-btn-clr);
    cursor: pointer;
}

.list-page-content{
    padding: 0px 10px;
}

.list-page-content table{
position: relative;
border-collapse: collapse;
background-color: white;
}

.list-page-content table th{

    position: sticky;
    top: 0;
    background-color: white;
}

.list-page-row-btns{
    display: flex;
    border:none;
}

.list-page-row-btns-th{
    width:100px;
    position: sticky;
    top: 0;
    background-color: white;
}

.list-page-pagination{
    padding-top: 10px;
    padding-left: 5px;
}

.list-page-footer{
    display: flex;
    /* position: absolute; */
    /* bottom: 10px; */
    width: 100%;
    background: white;
    padding:5px;
    border-radius: 10px;
}

.list-page-footer-pagination{
    display: flex;
    background-color: var(--secondary-btn-clr);
    border-radius: 50px;
    padding:0px;
}

.list-page-footer-pagination .input-ctrl{
    margin-top: 6px;
    margin-bottom: 0px;
}
.list-page-footer-pagination select{

}

.list-page-mobile-header{
    overflow: hidden;
    background-color:white;
    position: fixed;
    top: 0;
    width: 100%;
    /* height: 56px; */
    padding: 10px;
    z-index: 400;
    display: flex;
    border-bottom: solid 1px var(--secondary-btn-clr);
}


.list-page-mobile-header .right-30 .img-btn{
    margin-left: 5px;
}
.list-page-mobile-header-title{
    font-size: 20px;
    padding-top: 10px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-page-mobile-new-button{
    position: fixed;
    right: 10px;
    bottom: 90px;
    /* z-index:3000; */
    z-index:400;

}

.list-page-mobile-new-button svg{
    fill: white;
}

.list-page-mobile-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    z-index: 2;
    padding: 10px;
    border-top: solid 1px var(--secondary-btn-clr);
}

.list-page-mobile-footer-pagination{
    display: flex;
    background-color: var(--secondary-btn-clr);
    border-radius: 50px;
    padding: 0px;
    justify-content: center;
}

.list-page-mobile-footer-pagination .input-ctrl{
    margin-top: 6px;
    margin-bottom: 0px;
}

.list-page-mobile-background{
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: -1;
}
/* List Page end */


/* Import start */

.list-page-import{
    height: 100%;
    position: fixed;
    z-index: 1000;
    right: -560px;
    top: 0;
    transition: all .5s;
    display: flex;
}

.list-page-import-show{
    right: 0px;
}

.list-page-import-container{
    background-color: white;
    /* width: 460px; */
    width: 100%;
    overflow: auto;
}

/* /Import start */
.import-header{
    padding: 10px;
    text-align: center;
    border-bottom: solid 1px var(--btn-border-clr);
    height: 46px;
    font-size: 18px;
}

.import-content{
    background-color: white;
    /* width: 460px; */
    width: 100%;
    overflow: auto;
    padding: 10px;
    height: 80vh;
}

.import-footer{
   display: flex;
   /* position: fixed; */
   bottom: 0;
   /* width: 460px; */
   width: 100%;
   padding:10px 10px 0px 10px;
   border-top: solid 1px var(--btn-border-clr);
}
/* Import end */

.action-btn{
    width: 50px;
    height: 50px;
    background-color: var(--primary-clr);
    border-radius: 32px;
    border: solid 1px var(--primary-clr);
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all .5s;
    position: fixed;
    right: 10px;
    bottom: 90px;
    z-index: 400;
}

.action-btn svg{
    fill:white;
}
.tabstrip{
    overflow: auto; 
    white-space: nowrap;
    padding:10px;
 }

.tabstrip-button{
    background: white;
    border: solid 1px var(--primary-clr);
    color: var(--primary-clr);
    height: 46px;
    border-radius: 32px;
    margin-right: 5px;
}

.tabstrip-button-selected{
    background: var(--primary-clr);;
    border: solid 1px var(--primary-clr);
    color: white;
    height: 46px;
    border-radius: 32px;
    margin-right: 5px;
}
.productquickadd-image{
    height: 110px;
    width: 110px;
    -o-object-fit: cover;
       object-fit: cover;
    vertical-align: middle
}

.productquickadd-th-switch{
    width: 80px;
}

.productquickadd-th-input{
    width: 100px;
}



.barcode-button {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--secondary-btn-clr);;
    /* color: #fff; */
    border: none;
    border-radius: 32px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .barcode-image {
    /* margin-right: 20px;
    margin-left: 20px; */
padding-right:20px ;
  }

  .barcode-button span{
    margin-left: 20px;
  }
.issue-img{
    height: 300px;
}

.pagesection{
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */

}

.pagesection-header{
    font-family: 'Nunito';
    font-size: 18px;
    padding: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
}


.pagesection-body{
    padding:10px;
}

.page-mobile-header{
    overflow: hidden;
    background-color:white;
    position: fixed;
    top: 0;
    width: 100%;
    /* height: 56px; */
    padding: 10px;
    z-index: 400;
    display: flex;
    border-bottom: solid 1px var(--secondary-btn-clr);
}

.page-mobile-header-title{
    font-size: 20px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page-mobile-content{
    padding-top: 67px;
    margin:10px;
}

.page-mobile-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    z-index: 2;
    padding: 10px;
    border-top: solid 1px var(--secondary-btn-clr);
}
.productquickedit-page-header-title {
    font-size: 28px;
    padding-top: 5px;
    text-transform: uppercase;
  }

  .productquickedit-prodcut-image {
    width: 110px;
    height: 110px;
    overflow: hidden;
  }

  .productquickedit-prodcut-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
.productquickedit-page-header-title {
    font-size: 28px;
    padding-top: 5px;
    text-transform: uppercase;
  }

  .productquickedit-prodcut-image {
    width: 110px;
    height: 110px;
    overflow: hidden;
  }

  .productquickedit-prodcut-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .productquickedit-th-image{
    width: 110px;
  }

  .productquickedit-th-mrp{
    width: 110px;
  }
  .productquickedit-th-retailprice{
    width: 110px;
  }
.sales-sub-text {
    font-size: 10px;
}

.payment-container-left {
    background-color: white;
    margin-right: -10px;
    padding-top: 10px;
    padding-right: 10px;
    min-height: 82vh;
}

.payment-container-right {
    background-color: white;
    padding-top: 10px;
    /* padding-left:10px;
    padding-right: 10px; */
    min-height: 82vh;
}

.payment-container-section {
    min-height: 60vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 100px; /* height of your footer */
}

.footer1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
}


.pay-btn small {
    margin-left: 20px;
}

.pad {
    padding-top: 10px;
    padding-right: 10px;
    margin-top: 0px;
}





.item-list ::-webkit-scrollbar{
    display: none
}

.item-list-scroll { 
    height: 66vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.line-item-list-scroll-long {
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.line-item-list-scroll-short {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll2::-webkit-scrollbar {
    width: 5px;
}

.scroll2::-webkit-scrollbar-thumb {
    /* background: #666;  */
    background: silver;
}

.payment-section {
    min-height: 80vh;
    background-color: white;
    margin-top: 0px;
}

.product-selection-grid {
    /* display: flex;
    flex-wrap: wrap; */
    overflow-y: scroll;
    height: 66vh;
    /* padding-top: 5px; */
    /* padding-left:5px */
    /* max-width: 1000px; */
    /* margin: 0 auto; */
}

.category-selection-btn {
    padding: 5px;
    width: 94%;
    height: 100px;
    margin: 5px;
}

.category-selection-btn-selected {
    padding: 5px;
    width: 94%;
    height: 100px;
    margin: 5px;
    border: solid 2px #00a65a;
    background-color: #00a65a;
    color: white;
}
.category-selection-btn-selected:hover {
    padding: 5px;
    width: 94%;
    height: 100px;
    margin: 5px;
    border: solid 2px green;
    background-color: green;
    color: white;
}

.product-select-btn-text1 {
    color: #000;
    display: -webkit-box;
    margin: 4px;
    font-size: 14px;
    height: 3.6em;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-select-btn-text-full1 {
    color: #000;
    display: -webkit-box;
    margin: 4px;

    height: 3.6em;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .product-select-btn-text-full-product-name{
    font-size: 17px;
    margin: 0px;
    white-space: normal;
    height:70px;
    overflow: hidden;
    text-overflow: ellipsis;
} */

.product-select-btn-text-full-product-name {
    font-size: 17px;
    margin: 0px;
    white-space: normal;
    height: 70px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.product-select-btn-text-full-product-name1 {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px 5px;
    text-overflow: clip;
    box-sizing: border-box;
    overflow: hidden !important;
    overflow-wrap: unset;
    word-break: break-all;
    width: 100%;
    height: 100%;
    margin: 0;
}

.product-select-btn-text-full-product-price1 {
    height: 30px;
    font-size: 12px;
    margin: 0px;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-select-btn-text-name1 {
    width: 100%;
    height: 40%;
    margin: 1px;
    font-weight: bold;
    padding: 5px;
    box-sizing: border-box;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 5px;
    text-align: center;
}

.product-select-btn-text-name {
    /* height:95px; */
    font-size: 12px;
    /* color: #000; */
    display: -webkit-box;
    margin: 0px;
    /* height: 3.6em; */
    white-space: normal;
    font-weight: normal;
    text-align: center;
    line-height: 1.2em;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-select-btn-text-price1 {
    height: 40px;
    font-size: 12px;
    margin: 4px;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.photo {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

/* product container */
.product-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-left: 30px;
}

/* product variants-div */
@media screen and (max-width: 480px) {
    .product-variants-div .box-body .product-container .product-selection-btn {
        /* background: red; */
        width: 27%;
        height: 60px;
    }
}
.amount-quantity-div {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 3px 0px;
}

@media screen and (max-width: 500px) {
    .styles_overlay__CLSq- {
        /* background: red; */
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: white;
    }
    .styles_modal__gNwvD {
        padding-left: 0;
        padding-right: 0;
    }
    .styles_overlay__CLSq- {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    .styles_modalCenter__L9F2w {
        width: 100%;
        height: 100%;
        margin: 0;
    }
    .box-footer {
        bottom: 0;
        position: fixed;
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .content-wrapper {
        min-height: 100% !important;
    }
    .box-body {
        height: 80vh !important;
        overflow-y: scroll;
    }
    .btn-rounded {
        width: 45%;
        border-radius: 20px !important;
        margin: 0 5px;
    }
    .newHeight {
        height: 60vh !important;
    }
    .nav-tabs {
        display: flex !important;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 10px;
        overflow-y: scroll !important;
    }
    .btn-rounded2 {
        width: 90%;
        border-radius: 20px !important;
    }
    .errorFooter {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
}



.item-list-scroll2 {
    height: 66vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
}
.customer-search{
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.customer-search-rounded{
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.customer-search-rounded .rbt-input-main{
    /* padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 35px */
}


.customer-search-rounded .input-group .form-control:first-child
{
    border-radius: 35px;

}
.customer-search-selected-customer{
    display: flex;
    border-radius: 35px;
    background: var(--secondary-btn-clr);
    border:1px solid #ccc;
    cursor: pointer;
    /* height: 50px;
    padding: 2px; */
   
}
.customer-search-selected-customer-left{
    justify-content: flex-start;
    width: 75%;
    padding:5px 5px 5px 22px;
    display: flex;
    flex-flow: column;
}

.customer-search-selected-customer-right{
    display: flex;
    justify-content: flex-end;
    width:25%;
    
}

.customer-search-selected-customer-phone{
    font-size: 10px;
}


.customer-search-lineitem{
    display: flex;
    border-bottom: solid 1px var(--btn-border-clr);
    width: 100%;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top:10px;
}

.customer-search-lineitem-maintext{
    font-weight: bold;
    font-size: 18px;
    display: flex;
}

.customer-search-lineitem-subtext{

}

.customer-search-selected-status{
    margin-top:5px;
    font-size: 18px;
}

.customer-search-selected-status span{
    margin-left: 10px;
}

.fab-overlay{
  background: rgba(103, 118, 140, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  cursor: default;
}

.fab {
    width: 56px;
    height: 56px;
    background-color:var(--primary-clr);
    border-radius: 50%;
    box-shadow: 0 6px 10px 0 #666;
    transition: all 0.1s ease-in-out;
  
    font-size: 30px;
    color: white;
    text-align: center;
    line-height: 60px;
  
    position: fixed;
    right: 10px;
    bottom: 40px;
    z-index:3000;
 }

 .fab svg{
   color: white;
 }
 
 .fab:hover {
    box-shadow: 0 6px 14px 0 #666;
    transform: scale(1.05);
 }

 .fab-buttons {
    
    position: fixed;
    right: 10px;
    bottom: 105px;
    z-index:3000;
  }

  .fab-button-row {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    right: -5px;
    padding-right: 5px;
    padding-left: 5px;
    align-items: center;
    cursor: pointer;
    padding-bottom:20px;
}
  .fab-button {
    width: 56px;
    height: 56px;
    background-color:white;
    /* background-color: #585858; */
    border-radius: 50%;
    box-shadow: 0 6px 10px 0 #666;
    transition: all 0.1s ease-in-out;
  
    font-size: 30px;
    color: white;
    text-align: center;
    line-height: 56px;
  
  }

  .fab-button-text{
    background: white;
    border-radius: 3px;
    color: #67768c;
    max-height: 2em;
    font-weight: 600;
    padding: 2px 10px;
    pointer-events: none;
    white-space: nowrap;
    margin-right: 7px;
  }


.tabstrip{
    overflow: auto; 
    white-space: nowrap;
    padding:10px;
 }

.tabstrip-button{
    /* padding: 15px;
    background: white;
    border: none;
    outline-style:none; */
    background: white;
    border: solid 1px var(--primary-clr);
    color: var(--primary-clr);
    height: 46px;
    border-radius: 32px;
    margin-right: 5px;
}

.tabstrip-button-selected{
    background: var(--primary-clr);;
    border: solid 1px var(--primary-clr);
    color: white;
    height: 46px;
    border-radius: 32px;
    /* padding: 15px;
    background: white;
    color:var(--primary-clr);
    font-weight:bold;
    border-top:none;
    border-left:none;
    border-right:none;
    border-bottom: solid 4px var(--primary-clr);
    outline-style:none; */
}
.variant-title{
    font-weight: bold;
    margin-bottom: 10px;
}

.variants{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.variant{
    width: 24%;
    height: 120px;
    border: solid 1px var(--btn-border-clr);
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    word-break: break-all;
    padding: 3px;
    font-size: 13px;
}


.variant-dummy{
    width: 24%;
    height: 120px;
    /* border: solid 1px var(--btn-border-clr); */
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    word-break: break-all;
    padding: 3px;
    font-size: 13px;
}
.sales-mobile-header {
    /* background-color: white; */
    position: fixed;
    width: 100%;
    top: 56px;
    left: 0;
    right: 0;
    z-index: 99;
}

.sales-mobile-header-buttons {
    background-color: white;
    width: 20%;
    border: none;
    height: 75px;
    text-decoration: none;
    outline-style: none;
    border-bottom: 1px solid rgb(221, 221, 221);
}

.sales-mobile-header-buttons-customer-selected {
    color:var(--primary-clr);
}


.sales-mobile-header-buttons-selected {
    background-color: white;
    width: 20%;
    border: none;
    height: 75px;
    border-bottom: solid 4px var(--primary-clr);
    outline-style: none;
}

.sales-mobile-header-buttons div {
    font-size: 10px;
}

.sales-mobile-header-buttons-selected div {
    font-size: 10px;
}

.sales-mobile-list {
    height: 100%;
    padding-top: 70px;
    /* background-color: white; */
    /* padding-left: 10px;
    padding-right: 10px; */
}

.sales-mobile-list-white {
    height: 100%;
    padding-top: 75px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.sales-mobile-header-button-back {
    background-color: white;
    border: none;
    height: 60px;
    width: 40px;
    text-decoration: none;
    outline-style: none;
    padding-top: 20px;
    padding-left: 10px;
}

.sales-mobile-header-button-clear {
    background-color: white;
    border: none;
    height: 60px;
    width: 40px;
    text-decoration: none;
    outline-style: none;
    padding-top: 20px;
    padding-left: 10px;
}

.sales-mobile-header-button-add {
    background-color: white;
    border: none;
    height: 60px;
    width: 40px;
    text-decoration: none;
    outline-style: none;
    padding-top: 17px;
    padding-right: 10px;
}

.sales-mobile-header-input {
    border: none;
    margin-top: 10px;
    height: 40px;
    background-color: #f7f7f8;
    border-radius: 50px;
    width: 100%;
    padding: 10px;
    outline-style: none;
}

.sales-mobile-footer {
    height: 60px;
    background-color: white;
    padding-top: 7px;
    position: fixed;
    left: 0px;
    bottom: 0px;
    border-top: 1px solid rgb(221, 221, 221);
    width: 100%;
}

.button-category {
    overflow: hidden;
    width: 90%;
    /* height: 180px; */
    height: 110px;
    margin: 7px 5px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    /* background-color: white; */
    /* background: red; */
    border: 1px solid var(--btn-border-clr);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
}

.button-category-selected{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    background-color: var(--primary-clr);
    color:white;
    border-color: var(--primary-clr);
}

.button-category:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.button-product-category-image {
    width: 100%;
    height: 110px;
    /* max-height: 100px;
    min-height: 100px; */
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white;
}
.button-product-category-image img {
    height: 100%;
    height: 100%;
}

.button-category-sub-img {
    position: absolute;
    height: 40px;
    width: 100%;
    padding: 2px;
    color: white;
    text-align: center;
    line-height: 20px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
}

.button-product {
    overflow: hidden;
    width: 21%;
    min-width: 21%;
    height: 180px;
    margin: 7px 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid var(--btn-border-clr);
    position: relative;
    cursor: pointer;
}

.button-product:hover{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.button-product-image {
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
}

/* .button-product-image:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
} */

.button-product-image img {
    width: 100%;
    height: 100%;
}

.button-category-sub-text {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: bold;
    width: 100%;
    padding: 5px;
}
.btn-category-img-div {
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border-radius: 5px;
}
.btn-category-img-div img {
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    border-radius: 5px;
}

.button-sub-text-text {
    text-align: center;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    padding: 7px;
    font-weight: bold;
}

.button-sub-text-price {
    text-align: center;
    align-self: flex-start;
    width: 100%;
    /* margin: 6px; */
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    word-wrap: break-word;
    overflow: hidden;
    font-weight: 600;
    color: black;
    background-color:var(--secondary-btn-clr);
     /* rgba(0, 0, 0, 0.1); */
}

.button-sub-text-img {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    /* align-items: center; */
    margin-bottom: 5px;
    color: rgb(43, 42, 42);
    font-weight: bold;
    word-wrap: break-word;
    overflow: hidden;
    box-sizing: border-box;
    overflow: hidden;
}

/* .button-selected {
    border-color: var(--primary-clr);
    #00a65a;
    border-width: 2px;
} */

.option2 {
    width: 21%;
    display: inline-block;
}

/* mobile view code for product-selection-btn */
.product-selection-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5px;
    box-sizing: border-box;
    align-content: center;
}
.product-selection-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px !important;
    width: 30%;
    height: 120px;
    border: 2px solid lightgray;
    box-sizing: border-box;
    overflow: hidden;
    margin: 5px;
    box-sizing: border-box !important;
    overflow: hidden !important;
    background: white;
    position: relative;
}

.image-div {
    display: flex;
    width: 100%;
    height: 65px;
    box-sizing: border-box;
    overflow: hidden;
    overflow: hidden !important;
}
.product-select-btn-text-full-product {
    /* display: flex;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    padding: 3px 3px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: clip;
    box-sizing: border-box;
    width: 100%;
    overflow-wrap: unset;
    height: 80px;
    margin-top: auto;
    text-overflow: clip;
    word-break: break-all; */

    /* display: flex; */
    font-weight: bold;
    vertical-align: middle;
    width: 100%;
    padding: 3px;
    box-sizing: border;
    height: auto;
    max-height: 80px;
    overflow: hidden;
    text-align: center;
    margin-top: auto;
    word-break: break-all;
}

.product-select-btn-text-full-price {
    background-color: white;
    text-align: center;
    align-self: flex-end !important;
    margin: auto 0 0px 0;
    width: 100%;
    /* height: 20px; */
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* image with name of product */
.product-select-btn-img-text-full-product {
    text-align: center;
    width: 100%;
    height: 24px;
    overflow: hidden;
    /* font-size: 13px; */
    font-weight: bold;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    white-space: wrap;
    /* line-height: 13px; */
    padding: 0px 3px;
}

/* user selected product list */
.product-list-box {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box;
}

.product-list-box .item {
    display: flex;
    padding: 2px;
    margin: 7px 0;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color:var(--secondary-btn-clr);
    border-radius: 10px;
    overflow: hidden;
}
.product-list-box .item:first-child {
    margin-top: 0 !important;
}

.item-img {
    flex: 1 1;
    /* background-color: pink; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-info {
    flex: 2 1;
    display: flex;
    flex-direction: column;
    padding: 2px;
}

.item-price-and-Remove-item {
    flex: 1 1;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    height: 100%;
    overflow: hide;
    padding-left: 2px;
}
.product-quantity-selection-div {
    display: flex;
    margin-top: 4px;
}
.product-quantity-selection-btn {
    width: 23px;
    height: 23px;
    font-weight: bold;
    margin: 5px 0;
    outline: none;
    cursor: pointer;
    color: black;
    border: 1px solid black;
    align-items: center;
}
.inputDiv-product-quantity {
    outline: none;
    font-size: 13px;
    width: 70px;
    max-width: 100px;
    height: 23px;
    border: 1px solid black;
    margin: 5px 3px;
    text-align: center;
}

@media screen and (max-width: 320px) {
    
    .product-selection-btn {
        width: 45%;
    }
}
@media screen and (max-width: 280px) {
    
    .product-selection-btn {
        width: 45%;
    }
}



.button-category2 {
    overflow: hidden;
    width: 120px;
    min-width: 120px;
    height: 155px;
    margin: 7px 5px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    /* background-color: white; */
    /* background: red; */
    border: 1px solid var(--btn-border-clr);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
}

.button-product2 {
    overflow: hidden;
    width: 18%;
    min-width: 165px;
    width: 120px;
    min-width: 120px;
    height: 155px;
    margin: 7px 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid var(--btn-border-clr);
    position: relative;
}


/* new css  26march*/
.sales-container{
    margin: 76px 10px 0px 10px
}



#bootstrap-overrides .sales-productsearch-btns button:hover{
    color: white;
    background-color: var(--primary-clr);
    border-color: var(--primary-clr);
}


.sales-productsearch-btns button:hover svg{
    fill: white;
    background-color: var(--primary-clr)
}

.sales-product-list-item{
    display: flex;
    border-bottom: solid 1px var(--btn-border-clr);
    width: 100%;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top:10px;
}

.sales-product-list-item-maintext{
    font-weight: bold;
    font-size: 18px;
    display: flex;
}


.sales-product-tab {
    /* min-height: 76vh; */
    background-color: white;
    border-radius: 10px
}

.sales-item-list {
    /* height: 76vh; */
    background-color: white;
    border-radius: 10px;
    padding:10px;
    
    /* background-color:red */
}


.sales-selected-line-items{

}

.sales-selected-line-item{
    display: flex;
    border-bottom:1px solid #d2d6de;
    padding:10px 0px;
}


.sales-selected-line-item-content{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sales-selected-line-item-content-qty{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.sales-selected-line-item-avatar{
    cursor: pointer;
}

.sales-qty-control{
    display: flex;
}

.sales-qty-control button{
    width: 46px;
}

.sales-qty-control input{
    width: 50px;
}

.sales-selected-line-item-content-qty4{
    margin-right: 5px;
}

.sales-selected-line-item-avatar img{
    margin-right: 5px;
}
.sales-footer-btn:hover{
    color: var(--primary-clr)
}


 /* new mobile billing screen - start  */
 .sales-header{
    display: flex;
    padding: 10px;
    position: fixed;
    top:0px;
    background-color: white;
    justify-content:space-between;
    width: 100%;
    box-shadow: 0 5px 5px -5px var(--btn-border-clr);
    z-index: 101;
}

.sales-header-title{
    width: 85%;
    font-size: 20px;
    padding-top: 9px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sales-header-back{
    width: 15%;
}

.searchbar{
    width: 85%;
}

/* .sales-header input{
    
    height: 44px;
} */

.sales-content{
    margin-top: 66px;
    padding: 10px;
    /* background: white; */
    /* min-height: 80vh; */
}

.sales-content .button-secondary{
    margin-bottom: 10px;
}

.dummyBtns{
    width: 24%;
    height: 120px;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    word-break: break-all;
    overflow: hidden;
}

.categories{
   
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.category{
    width: 24%;
    height: 150px;
    border: solid 1px var(--btn-border-clr);
    border-radius: 10px;
    margin-bottom: 10px;
    /* padding: 2px; */
    justify-content: center;
    display: flex;
    align-items: center;
    /* word-break: break-all; */
    overflow: hidden;
    cursor: pointer;
    flex-direction: column;
    background-color: white;
}

.category-name{
    font-weight: bold;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   
}

.category-image{
    height: 90px;

}

.category-image img{
    height: 100%;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
}

.category-image-name{
    font-weight: bold;
    font-size: 13px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* background-color: var(--primary-clr); */
    background-color: var(--secondary-btn-clr);
    text-overflow: ellipsis;
    overflow: hidden;
    /* color: white; */
    text-align: center;
    width: 100%;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.products{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product{
    width: 24%;
    height: 150px;
    border: solid 1px var(--btn-border-clr);
    border-radius: 10px;
    margin-bottom: 10px; 
    text-align: center;
    background-color: white;
}

.product-name{
    /* word-break: break-all; */
    padding: 3px;
    
    font-size: 13px;
    height: 130px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-price{
    background-color:  rgba(0, 0, 0, 0.5);
    color: white;
    /* background-color: var(--secondary-btn-clr); */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 20px;
    font-weight: bold;
    font-size: 13px;
}

.product-image{
    height: 70px;
}

.product-image img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        vertical-align: middle;
}

.product-image-name{
    font-weight: bold;
    font-size: 13px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* background-color: var(--secondary-btn-clr); */
    background-color:  rgba(0, 0, 0, 0.5);
    color: white;
    
    text-overflow: ellipsis;
    /* word-break: break-all; */
    overflow: hidden;
    /* color: white; */
    text-align: center;
    width: 100%;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.product-image-price{
    font-weight: bold;
    font-size: 13px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* background-color: var(--secondary-btn-clr); */
    background-color:  rgba(0, 0, 0, 0.5);
    color: white;
    
    text-overflow: ellipsis;
    overflow: hidden;
    /* color: white; */
    text-align: center;
    width: 100%;
    height: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.back{
    width: 24%;
    height: 150px;
    border: solid 1px var(--btn-border-clr);
    border-radius: 10px;
    margin-bottom: 10px; 
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.back svg{
    height: 25px;
}

.sales-new-mobile-footer{
    display: flex;
    padding: 10px;
    position: fixed;
    bottom:0px;
    background-color: white;
    justify-content:space-between;
    width: 100%;
    box-shadow: 0 -5px 5px -5px var(--btn-border-clr);
}

.salesreturn-negative-qty{
    padding: 10px;
    position: fixed;
    bottom: 55px;
    width: 100%;
    background: white;
}

.sales-new-mobile-footer .total-qty{
    /* width: 50%; */
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    background-color: var(--secondary-btn-clr);
    border-radius: 32px;
}

.sales-new-mobile-footer .pay-btn1{
    height: 46px;
    border-radius: 50px;
    /* background-color: var(--primary-clr); */
    color: white;
    /* width: 50%; */
    padding:10px 20px;
    display: flex;
    justify-content:space-between;
    font-size: 18px;
    font-weight: bold;
}

.sales-new-mobile-footer .button-rounded{
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: bold;
}

.newsale{
    background-color: var(--primary-clr);
}

.editsale{
    background-color: #0288d1;
}

.salereturn{
    background-color: #ff6f00;
}

.no-lineitem{
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.dummyLineItem{
    height: 100px;
    width: 100%;
}

.lineitem{
    display: flex;
    padding: 10px;
    background-color: var(--secondary-btn-clr);
    margin-bottom:10px ;
    border-radius: 10px;
    font-size: 13px;
    justify-content: space-evenly;
    width: 100%;
}

.lineitem-content{
    width: 85%;
}

.lineitem-delete{
    width: 15%;
}

.lineitem-product-name{
    font-weight: bold;
}
.lineitem-row
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.lineitem-qty{
    border:solid 1px black;
    width: 122px;
    border-radius: 30px;
}

.lineitem-qty input{
    width: 40px;
    height: 30px;
    border: none;
    text-align: center;
}

.lineitem-qty-btn-right{
    height: 30px;
    width: 40px;
    padding-top: 2px;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    background: var(--secondary-btn-clr);
    border:none;
}

.lineitem-qty-btn-left{
    height: 30px;
    width: 40px;
    padding-top: 2px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    background: var(--secondary-btn-clr);
    border:none;
}

.payment-row{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.payment-label-lg{
    font-weight: bold;
    font-size: 20px;
}

.payment-amt-lg{
    font-weight: bold;
    font-size: 20px;
}

.payment-mode-added{
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}


.sales-page-search{
    display: flex;
    height: 46px;
    border-radius: 32px;
    border: solid 1px var(--btn-border-clr);
    background: var(--secondary-btn-clr);
    width: 100%;
}

.sales-page-search input{
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    height: auto;
    border:none;
    width: 80%;
}

.sales-page-search-btn-grp{
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    display: flex;
}

.sales-page-search-btn-grp .img-btn{
    height: 44px;
    width:44px;
}

.sales-page-search-btn-search{
    text-align: center;
    padding: 12px 20px;
    background: var(--secondary-btn-clr);
    cursor: pointer;
}

.sales-page-search-btn-clear{
    border-top-right-radius: 32px; 
    border-bottom-right-radius: 32px;
    text-align: center;
    padding: 12px 20px;
    background: var(--secondary-btn-clr);
    cursor: pointer;
}

.productlist{
    /* margin-top: 50px; */
}

.productlist-remove{
    position: fixed;
    right: 10px;
}

.productlist-row{
    border-bottom: solid 1px var(--secondary-btn-clr);
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.productlist-name{
    font-size: 13px;
    font-weight: bold;
}

.productlist-sku{
    font-size: 13px;
}


.productlist-price{
    font-size: 13px;
}

.payment-selected-paymentmode{
    padding-bottom:10px;
    font-size: 18px;
}

.payment-total{
    font-size: 35px;
    padding-bottom: 5px;
}

.payment-text{
    font-size: 18px;
    padding-bottom: 10px;
}

.payment-amount{
    padding-left: 0px; 
    padding-right: 0px;
}

.payment-remarks{
    padding-right: 0px;
}


.product-thumbnail{
    height: 46px;
    width: 46px;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 10px;
    margin-right: 10px;
    -o-object-fit: cover;
       object-fit: cover;
    border: solid 1px var(--btn-border-clr);
}

.product-thumbnail-text{
    height: 46px;
    width: 46px;
    /* background-color: var(--secondary-btn-clr); */
    background-color: var(--btn-border-clr);    
    margin-right: 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px var(--btn-border-clr);
}

.align-right{
    text-align: right;
}

.sales-screenoption3-lineitem-container{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.delivery-customer{
    padding: 10px 0px;
}

.delivery-lineitems{
    border: solid 1px  #E5E5E5;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0px;
}

.delivery-lineitems table{
    margin-top:10px;
}

.delivery-paid{
    background-color: #00a65a;
    border-radius:10px;
    padding:5px;
    color: white;
}
.delivery-balance{
    background-color: orangered;
    border-radius:10px;
    padding:5px;
    color: white;
}

.delivery-by{
    margin-top:10px;
}

.has-error{
    color: #a94442;
}

.has-error a{
    color: #a94442;
}

.product-with-image{
    display:flex;
}

/* .product-with-image img{width: 50px; margin-right: 10px} */


.left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .w100{
    width: 100%;
    
  }
  
  .w85{
    width: 85%;
  }
  
  .w70{
    width: 70%;
  }
  
  .w65{
    width: 70%;
  }
  
  .w50{
    width: 50%;
  }
  
  .w35{
    width: 35%;
  }
  
  .w30{
    width: 30%;
  }
  
  .w15{
    width: 15%;
  }
  
  .felx{
      display: flex;
      margin-bottom: 10px;
  }

  .posible_login_bg{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/11A%2Fposible_bg.webp?alt=media&token=29126f17-5081-465b-a2c1-5e55c6cc8832");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .posible_register_bg{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/11A%2Fposible_bg.webp?alt=media&token=29126f17-5081-465b-a2c1-5e55c6cc8832");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .posible_forgotpassword_bg{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/11A%2Fposible_bg.webp?alt=media&token=29126f17-5081-465b-a2c1-5e55c6cc8832");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .gcissue-info{
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    padding:10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .gcissue-info a{
    font-weight: bold;
  }

.salesoptions button{
    margin-bottom: 10px;
}

  .productselection-prodcut-image {
    width: 110px;
    height: 110px;
    overflow: hidden;
  }

  .productselection-prodcut-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .productselection-prodcut-product{
    width: 300px;
  }

.productselection-prodcut-price{
    text-align: right;
}

.delivery-list-header{
    padding: 10px !important;
    display: flex;
    height: 66px;
    text-transform: uppercase;
    letter-spacing: 2px;
    width: 100%;
    justify-content: space-between;
}

.delivery-list-tabs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.delivery-btn-green{
    background-color: #28a745;
    color: white;
}

.delivery-list-table{
    margin-top:10px;
}

.delivery-list-table  tbody tr{
background-color: white !important;
}

.align-right{
    text-align: right;
}

.delivery-list-lg-font{
    font-size: 18px;
}

.delivery-balance-amt{
    background-color: #dc3545;
    color: white;
}

.btn-lg{
    font-size: 14px;
}

.deliverylist-showmore{
   margin-bottom: 5px;
}

.deliverylist-tr-noshow{
    display: none;
}

.delivery-online-order-sufix{
    font-size: 22px;
    font-weight: bold;
}

.delivery-list-mobile-status-filter{
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    padding: 10px;
    /* position: absolute; */
    margin-top: 66px;
}

.delivery-list-mobile-page-content{
    padding: 0 10px;
    /* position: absolute; */
    /* margin-top: 130px; */
}

.delivery-list-mobile-row-seperator{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    padding: 16px 0px;
    border-bottom: 1px solid #dee3e7;
}

.delivery-list-image-th-width{
    width: 110px;
}
.delivery-list-product-image{
    width: 110px;
    height: 110px;
    overflow: hidden;
  }

  .delivery-list-product-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .return-otp-btn{
    border: 1px solid var(--btn-border-clr);
    padding: 4px 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin-top: 3px;
    /* text-align: center; */
    /* white-space: nowrap; */
    /* vertical-align: middle; */
    border-radius: 10px;
    border-bottom: solid 2px var(--btn-border-clr);
  }

  .delivery-otp{

    color: #0B8043;
    border: 1px solid #0B8043;
    padding: 4px 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin-top: 3px;
    /* text-align: center; */
    /* white-space: nowrap; */
    /* vertical-align: middle; */
    border-radius: 10px;
    border-bottom: solid 2px;
  }

  .return-opt{
    color: #d93025;
    border: 1px solid #d93025;
    padding: 4px 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin-top: 3px;
    /* text-align: center; */
    /* white-space: nowrap; */
    /* vertical-align: middle; */
    border-radius: 10px;
    border-bottom: solid 2px;
  }
/* .filter-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 52px;
    left: 0;
    background-color:white;
    overflow-x: hidden;
    transition: 0.5s;
  } */

  .filter{
    transition: 0.5s;
  }

  .filter-overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
    cursor: default;
   
}

  .filter-content{
    height: 100%;
    width: 80%;
    position: fixed;
    z-index: 3000;
    right: 0;
    top: 0;
    /* padding:20px 20px; */
    background-color: white;
    overflow: auto;
  }

  .filter-header{
    position: fixed;
    width:80%;
    padding: 14px;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    border-bottom: 1px solid #dee3e7;
    background-color: white;
  }

  .filter-title{
    line-height: 18px;
    text-align: center;
    flex-grow: 1;
    margin: 0;
    color: #101928;
    font-size: 20px;
    font-weight: 300;
  }

  .filter-close{

  }

  .filter-body{
    margin-top: 60px;
    margin-bottom: 60px;
    padding:10px;
  }

  .filter-footer {
    position: fixed;
    /* left: 0; */
    bottom: 0;
    width: 80%;
    padding:10px;
    background-color: white;
    text-align: center;
    z-index: 3001;
 }

 .filter-button-clearall
 {
     width:50%;
     height:46px;
     border:none;
     border-radius: 50px;
     background-color:#f7f7f8;
     outline-style: none;
 }

 .filter-button-apply
 {
     width:50%;
     height:46px;
     border:none;
     color: white;
     border-radius: 50px;
     background-color: #00a65a;
     outline-style: none;
 }
.popup-menu-overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
    cursor: default;
}

.popup-menu{
    position: fixed;
    bottom:20px;
    z-index: 3000;
    width:100%;
    padding:10px;
}

.popup-menu-options{
    background: white;
    box-shadow: 0 10px 20px 10px rgba(103, 118, 140, 0.25);
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #f7f7f8;
    margin-bottom: 10px;
}

.popup-menu-buttons{
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    text-transform: none;
    font-size: 18px;
    font-weight: 500;
    color: #101928;
    background: white;
    height:46px;
    -webkit-box-shadow: none;
    outline: none;
}

.popup-menu-buttons:not(:first-child) {
    border-top: 1px solid #eef0f2;
}

.delete{
    color: #f67c93;
}
.inventory-totals{
    display: flex;
    padding-bottom: 10px;
}

.inventory-totals-label{
    padding: 8px 10px 0px 0px;
}

.inventory-section{
    margin-bottom: 20px;
}

.inventory-product-info{
    margin-bottom: 10px;
}

.no-search{
    padding-top:50px;
}

.viewsales-section{
    margin-bottom: 20px;
}
.viewsales-section-header{
    /* padding-top: 40px; */
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
}

.viewsales-table-container{
    overflow-x: auto;
}

.text-align-right
{
    text-align: right;
}
.table tr {
    border-bottom: 1px solid #f4f4f4;
}

.table td {
    padding:10px;
}
.confirmation-overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
    cursor: default;
}

.confiramtion{
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 5000;

}

.confirmation-close
{
    padding:20px;
}

.confirmation-heading
{
    padding: 70px 0 8px 20px;
    font-weight: 700;
    font-size: 28px;
    color: #101928;
    line-height: 35px;
    width:100%;
}

.confirmation-text{
    font-size: 14px;
    font-weight: 400;
    color: #101928;
    line-height: 18px;
    width:100%;
    padding: 0px 20px;
}

.confirmation-button-section
{
    position: fixed;
    bottom: 0px;
    z-index: 3000;
    width: 100%;
    padding: 20px;
}

.confirmation-button1
{
    /* width:100%;
    font-size: 18px;
    font-weight: 500;
    
    
    text-transform: uppercase;
    height:44px; */


    width: 100%;
    border: none;
    border-radius: 50px;
    box-shadow: none;
    text-transform: none;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #f74969;
    height: 44px;
    outline: none;
}
/* .input-search{
    border-radius: 32px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 100;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    height: 46px;
    background-color: var(--secondary-btn-clr)
    background-color: rgba(241, 243, 244, 1);
}

.input-search span {
    margin-top: 8px;
    margin-right: 10px;
}

.input-search svg{
    height: 24px;
}

.input-search input {
    padding: 6px;
    padding-left: 28px;
    width: 100%;
    background-color: transparent;
    resize: none !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    border-radius: 32px;
} */

.google-place-input-search{
    
    display: flex;
    border: 1px solid #ccc;
    padding:5px;
    margin-bottom: 10px;
}

.google-place-input-search input{
    border:none;
    width:90%;
    /* outline: none !important; */
}

.google-place-input-search input:focus{
    /* border:none; */
    outline: none !important;
    border-color: inherit;
  box-shadow: none;
}

.google-place-input-search svg{
    height: 24px;
    fill:#ccc;
}

.btn-current-location{
    background-color: var(--clr);
    border-color: var(--clr);
    color: white;
    margin-bottom: 10px;
    width: 100%;
    line-height: 1.5;
    border-radius: .25rem;
}

.pac-container {
    z-index: 10051 !important;
}


.menu-onlineoffline-container{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 8px;
    margin-top: 15px;
    padding: 2px 10px;
    margin-bottom: 15px;
    height: 225px;
}
  
  .card-h3{
      margin-top: 2px;
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .card-img {
    border-radius: 8px 8px 8px 8px;
    position: absolute;
    left: 285px;
    display:block;
    width: 10%;
    top:16px;
  }
  
.image-container {
    position: relative;
    text-align: center;
    color: black;
    background-color: white;
    height: 100%;
  }
  
  .bottom-left {
    position: absolute;
    top: 90%;
    left: 7%;
    transform: translate(-7%, 30%);
    font-family: Roboto;
    font-size: 20px;
}
  
  .top-left {
    position: absolute;
    top: 8px;
    left: 0px;
  }
  
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  
  .centered {
    position: absolute;
    top: 5%;
    left: 7%;
    transform: translate(-7%, -50%);
    font-family: Roboto;
    font-size: xx-large;
  }

  .message-img{
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }
.kot-customer-display-section{
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
}

.kot-customer-display-pending-title{
    font-size: 30px;
    text-transform: uppercase;
}

.kot-customer-display-ready-title{
    font-size: 30px;
    text-transform: uppercase;
}

.kot-customer-display-pending{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    background-color: #488AE0;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
}
.kot-customer-display-ready{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    background-color: #BDECB7;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
}

.table-button{
    overflow: hidden;
    width: 200px;
    height: 100px;
    /* margin: 5px; */
    position: relative;
    display: -ms-flexbox;
    flex-direction: column;
    /* background-color: hsl(0deg 0% 94% / 89%); */
    background-color:white!important;
    border: 1px solid #ddd;
    display: inline-block;
    /* border-radius: 10px; */
}

.table-button-running{
    overflow: hidden;
    width: 200px;
    height: 100px;
    /* margin: 5px; */
    position: relative;
    display: -ms-flexbox;
    flex-direction: column;
    border: 1px solid #ddd;
    display: inline-block;
    /* border-radius: 10px; */
    background-color: rgb(0, 166, 90)!important;
    color: white
}

.table-button-billprinted{
    overflow: hidden;
    width: 200px;
    height: 100px;
    /* margin: 5px; */
    position: relative;
    display: -ms-flexbox;
    flex-direction: column;
    border: 1px solid #ddd;
    display: inline-block;
    /* border-radius: 10px; */
    background-color: #00c0ef!important;
    color: white
}
.table-button-tableno
{
    text-align: center;
    position: absolute;
    /* top:10px; */
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    /* background-color: hsl(0deg 0% 94% / 89%)!important; */
    /* flex: 1 1 auto;
    min-height: 1px; */
    padding: 5px;
}

.table-button-amt
{
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.table-button-qty{
    position: absolute;
    bottom: 10px;
    right: 10px
}
.missingpopulraitems-prodcut-image {
    width: 110px;
    height: 110px;
    overflow: hidden;
  }

  .missingpopulraitems-prodcut-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

.missingpopulraitems-add-btn-col{
    width: 100px;
}


.missingpopulraitems-page-header-title {
  font-size: 28px;
  padding-top: 5px;
  text-transform: uppercase;
}
.pincodeintegrationsetting-info{
    margin-bottom: 10px;
    font-weight: bold;
}

.pincodeintegration-col{
    width: 50%;
}
.report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

}

.report-header-title {
    font-size: 24px;
    font-weight: bold;
}

.report-header-back button{
border: none;
background-color: transparent;
}

.report-body{
    border-top: none;
    box-shadow: none;
    border-radius: 10px;
    background: white;
    padding: 10px;
    margin-bottom: 10px;
}
.report-button-search{
    background-color: var(--primary-clr);
    border-radius: 32px;
    border: 1px solid #6e2d90;
    border: 1px solid var(--primary-clr);
    color: #fff;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-image: none;
    margin-right: 10px;
    margin-bottom: 10px;
}

.report-button-clear{
    background-color: white;
    border-radius: 32px;
    border: 1px solid var(--primary-clr);
    color: var(--primary-clr);
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-image: none;
    margin-right: 10px;
    margin-bottom: 10px;
}

.report-button-export{
    border:none;
    color: var(--primary-clr);
    background: transparent;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-image: none;
    margin-right: 10px;
    margin-bottom: 10px;
}


