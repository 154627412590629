.action-btn{
    width: 50px;
    height: 50px;
    background-color: var(--primary-clr);
    border-radius: 32px;
    border: solid 1px var(--primary-clr);
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all .5s;
    position: fixed;
    right: 10px;
    bottom: 90px;
    z-index: 400;
}

.action-btn svg{
    fill:white;
}