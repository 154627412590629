/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.delivery-customer{
    padding: 10px 0px;
}

.delivery-lineitems{
    border: solid 1px  #E5E5E5;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0px;
}

.delivery-lineitems table{
    margin-top:10px;
}

.delivery-paid{
    background-color: #00a65a;
    border-radius:10px;
    padding:5px;
    color: white;
}
.delivery-balance{
    background-color: orangered;
    border-radius:10px;
    padding:5px;
    color: white;
}

.delivery-by{
    margin-top:10px;
}

.has-error{
    color: #a94442;
}

.has-error a{
    color: #a94442;
}

.product-with-image{
    display:flex;
}

/* .product-with-image img{width: 50px; margin-right: 10px} */


.left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .w100{
    width: 100%;
    
  }
  
  .w85{
    width: 85%;
  }
  
  .w70{
    width: 70%;
  }
  
  .w65{
    width: 70%;
  }
  
  .w50{
    width: 50%;
  }
  
  .w35{
    width: 35%;
  }
  
  .w30{
    width: 30%;
  }
  
  .w15{
    width: 15%;
  }
  
  .felx{
      display: flex;
      margin-bottom: 10px;
  }

  .posible_login_bg{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/11A%2Fposible_bg.webp?alt=media&token=29126f17-5081-465b-a2c1-5e55c6cc8832");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .posible_register_bg{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/11A%2Fposible_bg.webp?alt=media&token=29126f17-5081-465b-a2c1-5e55c6cc8832");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .posible_forgotpassword_bg{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/11A%2Fposible_bg.webp?alt=media&token=29126f17-5081-465b-a2c1-5e55c6cc8832");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .gcissue-info{
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    padding:10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .gcissue-info a{
    font-weight: bold;
  }
