.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 8px;
    margin-top: 15px;
    padding: 2px 10px;
    margin-bottom: 15px;
    height: 225px;
}
  
  .card-h3{
      margin-top: 2px;
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .card-img {
    border-radius: 8px 8px 8px 8px;
    position: absolute;
    left: 285px;
    display:block;
    width: 10%;
    top:16px;
  }
  