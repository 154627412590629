.customer-search{
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.customer-search-rounded{
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.customer-search-rounded .rbt-input-main{
    /* padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 35px */
}


.customer-search-rounded .input-group .form-control:first-child
{
    border-radius: 35px;

}
.customer-search-selected-customer{
    display: flex;
    border-radius: 35px;
    background: var(--secondary-btn-clr);
    border:1px solid #ccc;
    cursor: pointer;
    /* height: 50px;
    padding: 2px; */
   
}
.customer-search-selected-customer-left{
    justify-content: flex-start;
    width: 75%;
    padding:5px 5px 5px 22px;
    display: flex;
    flex-flow: column;
}

.customer-search-selected-customer-right{
    display: flex;
    justify-content: flex-end;
    width:25%;
    
}

.customer-search-selected-customer-phone{
    font-size: 10px;
}


.customer-search-lineitem{
    display: flex;
    border-bottom: solid 1px var(--btn-border-clr);
    width: 100%;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top:10px;
}

.customer-search-lineitem-maintext{
    font-weight: bold;
    font-size: 18px;
    display: flex;
}

.customer-search-lineitem-subtext{

}

.customer-search-selected-status{
    margin-top:5px;
    font-size: 18px;
}

.customer-search-selected-status span{
    margin-left: 10px;
}