.productquickadd-image{
    height: 110px;
    width: 110px;
    object-fit: cover;
    vertical-align: middle
}

.productquickadd-th-switch{
    width: 80px;
}

.productquickadd-th-input{
    width: 100px;
}



.barcode-button {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--secondary-btn-clr);;
    /* color: #fff; */
    border: none;
    border-radius: 32px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .barcode-image {
    /* margin-right: 20px;
    margin-left: 20px; */
padding-right:20px ;
  }

  .barcode-button span{
    margin-left: 20px;
  }