.image-container {
    position: relative;
    text-align: center;
    color: black;
    background-color: white;
    height: 100%;
  }
  
  .bottom-left {
    position: absolute;
    top: 90%;
    left: 7%;
    transform: translate(-7%, 30%);
    font-family: Roboto;
    font-size: 20px;
}
  
  .top-left {
    position: absolute;
    top: 8px;
    left: 0px;
  }
  
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  
  .centered {
    position: absolute;
    top: 5%;
    left: 7%;
    transform: translate(-7%, -50%);
    font-family: Roboto;
    font-size: xx-large;
  }

  .message-img{
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }