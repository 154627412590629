/* form start */
.form-header{
    padding: 10px;
    text-align: center;
    border-bottom: solid 1px var(--btn-border-clr);
    height: 46px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.form-content{
    background-color: white;
    width: 500px;
    overflow: auto;
    padding: 10px;
    /* height: 80vh; */
}

.form-footer{
   display: flex;
   position: fixed;
   bottom: 0;
   width: 500px;
   padding:10px 10px 0px 10px;
   border-top: solid 1px var(--btn-border-clr);
   background: white;
}
/* form end */

/* form mobile start */

.form-mobile{
    z-index: 400;
    position: absolute;
    background-color: white;
    width: 100%;
    top: 0;
}

.form-mobile-header{
    overflow: hidden;
    
    position: fixed;
    top: 0;
    width: 100%;
    /* height: 56px; */
    padding: 10px;
    display: flex;
    border-bottom: solid 1px var(--secondary-btn-clr);
    background-color: white;
}

.form-mobile-header-title{
    font-size: 20px;
    /* padding-top: 10px; */
    padding-left: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.form-mobile-content{
    padding-top: 67px;
    margin:10px;
}

.form-mobile-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    z-index: 2;
    padding: 10px;
    border-top: solid 1px var(--secondary-btn-clr);
}

/* form mobile end */