.list-page-filter-overlay {
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    cursor: default;
}

.list-page-filter{
    height: 100%;
    position: fixed;
    z-index: 1000;
    right: -460px;
    top: 0;
    transition: all .5s;
    display: flex;
}

.list-page-filter-show{
    right: 0px;
}

.list-page-filter-container{
    background-color: white;
    /* width: 360px; */
    width: 100%;
    overflow: auto;
   
}

.list-page-filter-header{
    padding: 10px;
    text-align: center;
    border-bottom: solid 1px var(--btn-border-clr);
    height: 46px;
    font-size: 18px;
}

.list-page-filter-content{
    background-color: white;
    /* width: 360px; */
    width: 100%;
    overflow: auto;
    padding: 10px;
    /* height: 80vh; */
}

.list-page-filter-footer{
   display: flex;
   /* position: fixed; */
   bottom: 0;
   /* width: 360px; */
   width: 100%;
   padding:10px 10px 0px 10px;
   border-top: solid 1px var(--btn-border-clr);
}

.list-page-filter-close-button{
    
}

/* List Page start*/
.list-page{
    background:white;
    border-radius: 10px;
    /* width:100%; */
    margin:10px;
}

.list-page-image{
    height: 150px;
    width: 150px;
    object-fit: contain;
    /* object-fit: cover; */
}

.list-page-color{
    height: 50px;
    width: 50px;
}

.list-page-header{
    padding:10px !important;
    display: flex;
    height: 66px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.list-page-header-title{
    font-size: 28px;
    padding-top:5px;
}

.list-page-header .right .button-primary , 
.list-page-header .right .button-primary ,
.list-page-header .right .button-secondary , 
.list-page-header .right .button-secondary 
{
    margin-left: 5px;
    width:100px;
    margin-bottom: 0px;
}
.list-page-header .right .img-btn  {
    margin-left: 5px;
   
    /* width:120px; */
}

.list-page-search{
    display: flex;
    height: 46px;
    border-radius: 32px;
    border: solid 1px var(--btn-border-clr);
    background: var(--secondary-btn-clr);
    /* width: 400px;
    margin-left: 15px; */
}

.list-page-search input{
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    height: auto;
    border:none;
    width: 250px;
}

.list-page-search-btn-grp{
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    display: flex;
    /* background: white; */
}

.list-page-search-btn-grp .img-btn{
    height: 44px;
    width:44px;
}

.list-page-search-btn-search{
    text-align: center;
    padding: 12px 20px;
    /* border-right: solid 1px var(--btn-border-clr);
    border-left: solid 1px var(--btn-border-clr); */
    /* border-top-left-radius: 32px;
    border-bottom-left-radius: 32px; */
    background: var(--secondary-btn-clr);
    /* width: 90px; */
    cursor: pointer;
}

.list-page-search-btn-clear{
    border-top-right-radius: 32px; 
    border-bottom-right-radius: 32px;
    text-align: center;
    padding: 12px 20px;
    /* width: 90px; */
    background: var(--secondary-btn-clr);
    cursor: pointer;
}

.list-page-content{
    padding: 0px 10px;
}

.list-page-content table{
position: relative;
border-collapse: collapse;
background-color: white;
}

.list-page-content table th{

    position: sticky;
    top: 0;
    background-color: white;
}

.list-page-row-btns{
    display: flex;
    border:none;
}

.list-page-row-btns-th{
    width:100px;
    position: sticky;
    top: 0;
    background-color: white;
}

.list-page-pagination{
    padding-top: 10px;
    padding-left: 5px;
}

.list-page-footer{
    display: flex;
    /* position: absolute; */
    /* bottom: 10px; */
    width: 100%;
    background: white;
    padding:5px;
    border-radius: 10px;
}

.list-page-footer-pagination{
    display: flex;
    background-color: var(--secondary-btn-clr);
    border-radius: 50px;
    padding:0px;
}

.list-page-footer-pagination .input-ctrl{
    margin-top: 6px;
    margin-bottom: 0px;
}
.list-page-footer-pagination select{

}

.list-page-mobile-header{
    overflow: hidden;
    background-color:white;
    position: fixed;
    top: 0;
    width: 100%;
    /* height: 56px; */
    padding: 10px;
    z-index: 400;
    display: flex;
    border-bottom: solid 1px var(--secondary-btn-clr);
}


.list-page-mobile-header .right-30 .img-btn{
    margin-left: 5px;
}
.list-page-mobile-header-title{
    font-size: 20px;
    padding-top: 10px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-page-mobile-new-button{
    position: fixed;
    right: 10px;
    bottom: 90px;
    /* z-index:3000; */
    z-index:400;

}

.list-page-mobile-new-button svg{
    fill: white;
}

.list-page-mobile-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    z-index: 2;
    padding: 10px;
    border-top: solid 1px var(--secondary-btn-clr);
}

.list-page-mobile-footer-pagination{
    display: flex;
    background-color: var(--secondary-btn-clr);
    border-radius: 50px;
    padding: 0px;
    justify-content: center;
}

.list-page-mobile-footer-pagination .input-ctrl{
    margin-top: 6px;
    margin-bottom: 0px;
}

.list-page-mobile-background{
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: -1;
}
/* List Page end */


/* Import start */

.list-page-import{
    height: 100%;
    position: fixed;
    z-index: 1000;
    right: -560px;
    top: 0;
    transition: all .5s;
    display: flex;
}

.list-page-import-show{
    right: 0px;
}

.list-page-import-container{
    background-color: white;
    /* width: 460px; */
    width: 100%;
    overflow: auto;
}

/* /Import start */
.import-header{
    padding: 10px;
    text-align: center;
    border-bottom: solid 1px var(--btn-border-clr);
    height: 46px;
    font-size: 18px;
}

.import-content{
    background-color: white;
    /* width: 460px; */
    width: 100%;
    overflow: auto;
    padding: 10px;
    height: 80vh;
}

.import-footer{
   display: flex;
   /* position: fixed; */
   bottom: 0;
   /* width: 460px; */
   width: 100%;
   padding:10px 10px 0px 10px;
   border-top: solid 1px var(--btn-border-clr);
}
/* Import end */
