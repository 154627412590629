.missingpopulraitems-prodcut-image {
    width: 110px;
    height: 110px;
    overflow: hidden;
  }

  .missingpopulraitems-prodcut-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.missingpopulraitems-add-btn-col{
    width: 100px;
}


.missingpopulraitems-page-header-title {
  font-size: 28px;
  padding-top: 5px;
  text-transform: uppercase;
}