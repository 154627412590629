.report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

}

.report-header-title {
    font-size: 24px;
    font-weight: bold;
}

.report-header-back button{
border: none;
background-color: transparent;
}
