
/* 
h1, h2, h3, h4, h5 {
    font-family: 'Roboto';
} */

/* .sidebar-mini.sidebar-collapse .content-wrapper
{
    margin-left: 0px !important;
} */

.header-new-background{
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: -1;
}

.white-backround
{
    background-color: white;
}

.menubar {
    overflow: hidden;
    background-color: #00a65a;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    height: 56px;
    padding: 0px 10px;
    z-index:1000;
  }

  .menubar-logo
{
    width: 100%;
    text-align: center;
}

.menubar-logo img{
    width:80px;
    margin-top:6px;
}

.menubar-button{
    top: 0px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 10px;
    width: 40px;
    height: 100%;
}

.menubar-button-back{
    top: 0px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 10px;
    width: 40px;
    height: 100%;
}

  .menubar-title
  {
    color:white;
    font-size:17px;
    margin-top:15px;
    width: 100%;
    text-align: center;
    }

  .menubar-button-filter{
    top: 18px;
    position: absolute;
    right: 10px;
    color: white;
  }

  .menubar-button-edit{
    top: 16px;
    position: absolute;
    right: 10px;
    color: white;
  }

  .menubar-button-delete{
    padding-top:10px;
    padding-left:10px;
    padding-right:10px;
    color: white;
  }

  .menu-overlay {
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;
    cursor: default;
  }

  .menu{
    height: 100%;
    width: 80%;
    position: fixed;
    z-index: 3000;
    left: 0;
    top: 0;
    padding:20px 20px;
    background-color: white;
    overflow: auto;
    max-width: 300px;
    }

  

  .menu-row{
    display: flex; 
    flex-direction: row; 
    flex-grow: 1; 
    align-items: center;
    padding: 16px 0px;
  }

  .menu-text{
    font-size: 17px;
    /* font-weight:bold; */
    font-weight: 500;
    width:90%;
    padding-left:20px;
    padding-right:20px;
    color: #101928;
  }

  .menu-icon{
    align-self: flex-start;
    padding-top:5px;
}

  .menu-text-header{
    font-size: 17px;
    /* font-weight:bold; */
    font-weight: 500;
    width:80%;
    text-align:center;
    color: #101928;
  }

  .menu-sub-text
  {
    font-size:12px;
    padding-top:5px
  }

.menu-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 80%;
    padding:10px;
    background-color: white;
    text-align: center;
    max-width: 300px;
    z-index: 3001;

}

.menu-signout{
     
    height:46px;
    border:none;
    border-radius: 50px;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
    color: #101928;
    background-color:#f7f7f8;
    outline-style: none;
  }

  /* .content-wrapper{
      padding-top: 56px;
  } */
  
.container{
    padding: 0px 10px;
    background: white;
}

.row-title{
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
}

.row-sub-text{
    font-size: 13px;
}

.outstanding{
    margin-top: 5px;
    margin-right: 5px;
    font-size: 13px;
    background-color: #dd4b39 !important;
    /* display: inline; */
    padding: .2em .6em .3em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.wallet{
    margin-top: 5px;
    margin-right: 5px;
    font-size: 13px;
    background-color: #00a65a !important;
    /* display: inline; */
    padding: .2em .6em .3em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.membership{
    margin-top: 5px;
    margin-right: 5px;
    font-size: 13px;
    background-color: #f39c12 !important;
    /* display: inline; */
    padding: .2em .6em .3em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.loyalty{
    margin-top: 5px;
    margin-right: 5px;
    font-size: 13px;
    background-color: #f39c12 !important;
    /* display: inline; */
    padding: .2em .6em .3em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}


.list{
    height: 100%;
    padding-top: 60px;
}

.list-row{
    display: flex; 
    flex-direction: row; 
    flex-grow: 1; 
    align-items: center;
    padding: 16px 0px;
    
}

.list-image{
  width: 60px;
  margin-right: 20px;
}

.seperator{
    border-bottom: 1px solid #dee3e7;
}

.text1{
    font-weight: 700;
    font-size: 28px;
    color: #101928;
    line-height: 35px;
}

.field{
    font-size: 13px;
    color: #101928;
    font-weight: 400;
    line-height: 16px;

    word-wrap: break-word;
    max-width: 100%;
    min-width: 0;

    padding-top:20px;
}

.value{
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #101928;

    word-wrap: break-word;
    max-width: 100%;
    min-width: 0;

    padding-bottom:20px;
}

.address{
    padding-top:20px;
    padding-bottom:20px;
    border-bottom: 1px solid #dee3e7;
}

.address-main{
    font-size: 17px;
    color: #101928;
    font-weight: 400;
    padding-bottom:5px;
}

.address-main i{
    padding-right:5px;
}
.address-sub{
    font-size: 13px;
    color: #101928;
}

label{
    font-size: 13px;
    font-weight: 400;
    color: #101928;
    line-height: 16px;
}


.login-border-radius
{
    border-radius: 25px;
}

.login-button
{
    background-color: #00a65a;
    height: 46px;
    width:100%;
    border: none;
    color: white;
    outline-style: none;
}

/* .login-box
{
    text-align: center;
} */

.border-radius
{
    border-radius: 50px;
}

.home-sms
{
    width: 100%;
    background-color: white;
}

.no-top-padding
{
    padding-top: 0px
}

.selectRounded{
    border-radius:36px;
    display:inline-block;
    overflow:hidden;
    background:#cccccc;
    border:1px solid #cccccc;
  }

  .quick-add-button{
    width: 100%;
    border: none;
    border-radius: 50px;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: none;
    font-size: 18px;
    font-weight: 500;
    /* color: white; */
    /* background-color: #f74969; */
    height: 44px;
    outline: none;
  }