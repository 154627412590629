/* window start*/
.window-overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;
    cursor: default;
}

.window-header-borderless-mobile{
    position: absolute;
    top:0px;
    left: 0px;
    padding:10px;
    font-size: 20px;
    z-index: 6000;
}

.window-content-borderless{
    padding: 10px;
    overflow: auto;
    max-height: 80vh;
}

.window-content-borderless-mobile{
    margin-top:-10px;
    padding: 10px;
    overflow: auto;
    height: 100%;
    position: fixed;
    width: 100%;

}

.window-footer-mobile{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: white;
    /* color: white; */
    text-align: center;
}

.window-footer-mobile button{
    width: 100%;
    /* margin:10px; */
}

.window{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5000;
}

.window-mobile{
    position: fixed; /* Stay in place */
    z-index: 5000; /* Sit on top */
    left: 0;
    top: 0;
    background-color:white; 
}

.window-container{
    background-color:white; 
    border-radius: 10px;   
}

.window-container-mobile{   
}

.window-container .margin{
    margin-right: 46px;
}

.window-header{
    width: 100%;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    border-bottom: 1px solid #dee3e7;
    background-color: white;
    padding:10px;
    font-size: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.window-header-mobile{
    width: 100%;
    display: flex;
    border-bottom: 1px solid #dee3e7;
    background-color: white;
    z-index: 5001;
    padding:10px;
    font-size: 20px;
}

.window-close-btn{
    position: absolute;
    right: -48px;
}

.window-close-btn svg{
    fill:black;
    /* width: 12px; */
}

.window-content{
    padding: 10px;
    overflow: auto;
    max-height: 80vh
}

.window-content-mobile{
    padding: 10px;
    overflow: auto;
    height: 100%;
    width: 100%;
}

.window-footer{
    display: flex;
    width: 100%;
    padding: 10px 10px 0px 10px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.window-footer-mobile{
    display: flex;
    width: 100%;
    padding: 10px 10px 0px 10px;
    background-color: white;
    position: fixed;
    bottom: 0px;
}

.window-footer button{
    width: 100%;
    /* margin:10px; */
}

.address-action-button{
    padding-top: 10px;
}

.address-action-button a{
    padding: 10px 50px 10px 0px;
}

.window-mobile-header-title{
    font-size: 20px;
    padding-top: 10px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    height: 46px;
}

.window-bg-color{
    background-color: var(--secondary-btn-clr);
}
/* window end */

