/* .input-search{
    border-radius: 32px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 100;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    height: 46px;
    background-color: var(--secondary-btn-clr)
    background-color: rgba(241, 243, 244, 1);
}

.input-search span {
    margin-top: 8px;
    margin-right: 10px;
}

.input-search svg{
    height: 24px;
}

.input-search input {
    padding: 6px;
    padding-left: 28px;
    width: 100%;
    background-color: transparent;
    resize: none !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    border-radius: 32px;
} */

.google-place-input-search{
    
    display: flex;
    border: 1px solid #ccc;
    padding:5px;
    margin-bottom: 10px;
}

.google-place-input-search input{
    border:none;
    width:90%;
    /* outline: none !important; */
}

.google-place-input-search input:focus{
    /* border:none; */
    outline: none !important;
    border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.google-place-input-search svg{
    height: 24px;
    fill:#ccc;
}

.btn-current-location{
    background-color: var(--clr);
    border-color: var(--clr);
    color: white;
    margin-bottom: 10px;
    width: 100%;
    line-height: 1.5;
    border-radius: .25rem;
}

.pac-container {
    z-index: 10051 !important;
}


.menu-onlineoffline-container{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}