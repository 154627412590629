.inventory-totals{
    display: flex;
    padding-bottom: 10px;
}

.inventory-totals-label{
    padding: 8px 10px 0px 0px;
}

.inventory-section{
    margin-bottom: 20px;
}

.inventory-product-info{
    margin-bottom: 10px;
}