.delivery-list-header{
    padding: 10px !important;
    display: flex;
    height: 66px;
    text-transform: uppercase;
    letter-spacing: 2px;
    width: 100%;
    justify-content: space-between;
}

.delivery-list-tabs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.delivery-btn-green{
    background-color: #28a745;
    color: white;
}

.delivery-list-table{
    margin-top:10px;
}

.delivery-list-table  tbody tr{
background-color: white !important;
}

.align-right{
    text-align: right;
}

.delivery-list-lg-font{
    font-size: 18px;
}

.delivery-balance-amt{
    background-color: #dc3545;
    color: white;
}

.btn-lg{
    font-size: 14px;
}

.deliverylist-showmore{
   margin-bottom: 5px;
}

.deliverylist-tr-noshow{
    display: none;
}

.delivery-online-order-sufix{
    font-size: 22px;
    font-weight: bold;
}

.delivery-list-mobile-status-filter{
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    padding: 10px;
    /* position: absolute; */
    margin-top: 66px;
}

.delivery-list-mobile-page-content{
    padding: 0 10px;
    /* position: absolute; */
    /* margin-top: 130px; */
}

.delivery-list-mobile-row-seperator{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    padding: 16px 0px;
    border-bottom: 1px solid #dee3e7;
}

.delivery-list-image-th-width{
    width: 110px;
}
.delivery-list-product-image{
    width: 110px;
    height: 110px;
    overflow: hidden;
  }

  .delivery-list-product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .return-otp-btn{
    border: 1px solid var(--btn-border-clr);
    padding: 4px 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin-top: 3px;
    /* text-align: center; */
    /* white-space: nowrap; */
    /* vertical-align: middle; */
    border-radius: 10px;
    border-bottom: solid 2px var(--btn-border-clr);
  }

  .delivery-otp{

    color: #0B8043;
    border: 1px solid #0B8043;
    padding: 4px 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin-top: 3px;
    /* text-align: center; */
    /* white-space: nowrap; */
    /* vertical-align: middle; */
    border-radius: 10px;
    border-bottom: solid 2px;
  }

  .return-opt{
    color: #d93025;
    border: 1px solid #d93025;
    padding: 4px 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin-top: 3px;
    /* text-align: center; */
    /* white-space: nowrap; */
    /* vertical-align: middle; */
    border-radius: 10px;
    border-bottom: solid 2px;
  }