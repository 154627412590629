
.table-button{
    overflow: hidden;
    width: 200px;
    height: 100px;
    /* margin: 5px; */
    position: relative;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;
    /* background-color: hsl(0deg 0% 94% / 89%); */
    background-color:white!important;
    border: 1px solid #ddd;
    display: inline-block;
    /* border-radius: 10px; */
}

.table-button-running{
    overflow: hidden;
    width: 200px;
    height: 100px;
    /* margin: 5px; */
    position: relative;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid #ddd;
    display: inline-block;
    /* border-radius: 10px; */
    background-color: rgb(0, 166, 90)!important;
    color: white
}

.table-button-billprinted{
    overflow: hidden;
    width: 200px;
    height: 100px;
    /* margin: 5px; */
    position: relative;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid #ddd;
    display: inline-block;
    /* border-radius: 10px; */
    background-color: #00c0ef!important;
    color: white
}
.table-button-tableno
{
    text-align: center;
    position: absolute;
    /* top:10px; */
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    /* background-color: hsl(0deg 0% 94% / 89%)!important; */
    /* flex: 1 1 auto;
    min-height: 1px; */
    padding: 5px;
}

.table-button-amt
{
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.table-button-qty{
    position: absolute;
    bottom: 10px;
    right: 10px
}