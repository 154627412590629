.record-not-found{
    width: 100%;
    text-align: center;
}

.record-not-found svg{
    height: 60px !important;
}

.record-not-found-title
{
    font-weight: 700;
    font-size: 22px;
    color: #101928;
    line-height: 25px;
    margin-bottom: 16px;
}

.record-not-found-text{
    color: #67768c;
    font-size: 16px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 16px;
}