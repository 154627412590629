.variant-title{
    font-weight: bold;
    margin-bottom: 10px;
}

.variants{
    display: flex;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.variant{
    width: 24%;
    height: 120px;
    border: solid 1px var(--btn-border-clr);
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: white;

    word-break: break-all;
    padding: 3px;
    font-size: 13px;
}


.variant-dummy{
    width: 24%;
    height: 120px;
    /* border: solid 1px var(--btn-border-clr); */
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: white;

    word-break: break-all;
    padding: 3px;
    font-size: 13px;
}