.list-search{  
    background-color: white;
    position: fixed;
    padding:10px;
    top: 65px;
    left: 0;
    right: 0;
    z-index:99;
}

.list-search-div{
    border: 1px solid #dee3e7;
    border-radius: 32px;
    border-width: 1px;
    box-sizing: border-box;
    padding:8px;
    z-index:100;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.list-search-div input{
    padding: 6px;
    padding-left: 28px;
    width: 100%;
    background-color: transparent;
    resize: none !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border: none;
    outline: none;
    -webkit-appearance: none;
}

.list-search-div span{
    width: 16px;
    height: 16px;
    color: lightgray;
}

.list-search-div i{
    color: lightgray;
}