
.fab-overlay{
  background: rgba(103, 118, 140, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  cursor: default;
}

.fab {
    width: 56px;
    height: 56px;
    background-color:var(--primary-clr);
    border-radius: 50%;
    box-shadow: 0 6px 10px 0 #666;
    transition: all 0.1s ease-in-out;
  
    font-size: 30px;
    color: white;
    text-align: center;
    line-height: 60px;
  
    position: fixed;
    right: 10px;
    bottom: 40px;
    z-index:3000;
 }

 .fab svg{
   color: white;
 }
 
 .fab:hover {
    box-shadow: 0 6px 14px 0 #666;
    transform: scale(1.05);
 }

 .fab-buttons {
    
    position: fixed;
    right: 10px;
    bottom: 105px;
    z-index:3000;
  }

  .fab-button-row {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
    position: relative;
    right: -5px;
    padding-right: 5px;
    padding-left: 5px;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    padding-bottom:20px;
}
  .fab-button {
    width: 56px;
    height: 56px;
    background-color:white;
    /* background-color: #585858; */
    border-radius: 50%;
    box-shadow: 0 6px 10px 0 #666;
    transition: all 0.1s ease-in-out;
  
    font-size: 30px;
    color: white;
    text-align: center;
    line-height: 56px;
  
  }

  .fab-button-text{
    background: white;
    border-radius: 3px;
    color: #67768c;
    max-height: 2em;
    font-weight: 600;
    padding: 2px 10px;
    pointer-events: none;
    white-space: nowrap;
    margin-right: 7px;
  }