.avatar-circle {
    width: 60px;
    height: 60px;
    background-color: #f7f7f8;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;


    /* new */
    align-self: flex-start;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .bg{
    width: 128px;
    height: 128px;
  }

  .initials {
    /* position: relative; */
    /* top: 5px;  */
    font-size: 25px; /* 50% of parent */
    line-height: 60px; /* 50% of parent */
    color:#101928;
    /* font-weight: bold; */
  }

 .bg{
    font-size: 40px; /* 50% of parent */
    line-height: 128px; /* 50% of parent */
  }

  .avatar-user {
    width: 40px;
    height: 40px;
    background-color: #f7f7f8;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;


    /* new */
    align-self: flex-start;
    /* margin-right: 16px; */
    flex-shrink: 0;
    margin-top: -7px;
    
  }


  .initials-user {
    /* position: relative; */
    /* top: 5px;  */
    font-size: 25px; /* 50% of parent */
    line-height: 43px; /* 50% of parent */
    color:#00a65a;
    /* font-weight: bold; */
  }
