
:root {
    --primary-clr: rgba(110, 45, 144, 1);
     /* --primary-clr:#f2682a; */
    --secondary-btn-clr:#F0F2F5;
    --btn-border-clr:#d2d6de;
    --btn-imp-clr:#8F4FFA;
  }

  body{
      font-family: 'Nunito';
      background-color: var(--secondary-btn-clr);
  }

  #bootstrap-overrides h1{font-family:'Nunito'; 
    font-size: 26px;
    margin-top: 0px;
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 2px;
}

  #bootstrap-overrides h2 {font-family:'Nunito'; font-size: 18px;margin-top: 0px}
  #bootstrap-overrides h3,h4,h5{font-family:'Nunito'; font-size: 18px; margin-top: 0px}

/* old css start */

/* @media (min-width: 768px) {
.sidebar-mini.sidebar-collapse .content-wrapper, .sidebar-mini.sidebar-collapse .right-side, .sidebar-mini.sidebar-collapse .main-footer, .header-margin
{
    padding-top: 64px !important;
    margin-left: 0px !important;

}
} */

.content-wrapper{
    padding:15px;
    padding-top: 81px;
}

.content-header{
height: 40px;
}

.content-header button, .content-header a{
margin-top:0px !important;
height: 32px;
}

#bootstrap-overrides .content-header .btn-default{
background: white;
border: solid 1px var(--primary-clr);
color: var(--primary-clr);
}


#bootstrap-overrides  .btn-secondary{
    background: white;
    border: solid 1px var(--primary-clr);
    color: var(--primary-clr);
}

/* #bootstrap-overrides .content-header button:last-child{
    margin-right:66px !important;
    }

    #bootstrap-overrides .content-header a:last-child{
        margin-right:66px !important;
        } */

.main-footer{
    margin-left: 0px !important;
}

.main-container{
    /* margin:10px; */
}

.box{
    border-top:none;
    box-shadow: none;
    border-radius: 10px;
    background: white;
    padding:10px;
    margin-bottom: 10px;
}

.box-header{
    padding-bottom: 10px;
}

.input-group-addon{
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
    background: var(--secondary-btn-clr);
}

#bootstrap-overrides .btn{
    border-radius: 32px;
    outline: none;
}

/* .btn-success:active */
#bootstrap-overrides .btn-success , #bootstrap-overrides .btn-success:active ,#bootstrap-overrides .btn-primary , #bootstrap-overrides .btn-primary:active{
    background-color: var(--primary-clr);
    border-radius: 32px;
    border:solid 1px var(--primary-clr);
    /* padding-left:20px;
    padding-right:20px; */
    
}

#bootstrap-overrides .btn-default , #bootstrap-overrides .btn-default:active{
    background-color: var(--secondary-btn-clr);
    color: #5f6368;
    border-radius: 32px;
    /* border:solid 1px var(--secondary-btn-clr); */
    /* padding-left:20px;
    padding-right:20px; */
}

#bootstrap-overrides .btn-success:hover, #bootstrap-overrides .btn-primary:hover , #bootstrap-overrides .btn-default:hover{
    background-color: white;
    color:var(--primary-clr);
    border-radius: 32px ;
    border:solid 1px var(--primary-clr);
} 

#bootstrap-overrides .img-btn  .btn-primary svg{
    fill:white;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover
{
    background-color:var(--primary-clr);
    border-color:var(--primary-clr);
}

#bootstrap-overrides  .form-group .input-group .input-group-btn button{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border:solid 1px var(--btn-border-clr) !important;

}

#bootstrap-overrides  .form-group .input-group .input-group-btn button:hover{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border:solid 1px var(--btn-border-clr) !important;

}
/* #bootstrap-overrides .btn-success:active {
    background-color: var(--primary-clr);
    border-radius: 32px;
    border:solid 1px var(--primary-clr) ;
    padding-left:20px;
    padding-right:20px;
    }
     */
/* .input-group-btn button{
    border-radius: 0px !important;
     border: solid 1px #ddd !important;
 }
 
 .input-group-btn button:last-child{
     border-bottom-left-radius: 0px !important;
     border-top-left-radius: 0px !important;
     border-bottom-right-radius: 32px !important;
     border-top-right-radius: 32px !important;
     border: solid 1px #ddd !important;
 } */

 


/* .btn .btn-success .btn-flat .btn-md{
    background-color: var(--primary-clr);
    border-radius: 32px !important;
    border:solid 1px var(--primary-clr) !important;
    padding-left:20px;
    padding-right:20px;
    
} */

/* .btn-primary{
    background-color: var(--primary-clr);
    color: white;
    border-radius: 32px !important;
    border:solid 1px var(--primary-clr) !important;
} */



/* .btn-success:hover, .btn-success:active, .btn-success.hover
{
    background-color: var(--primary-clr);
    border-radius: 32px !important;
    border:solid 1px var(--primary-clr) !important;
    padding-left:20px;
    padding-right:20px;
} */


.form-control{
    border-radius: 32px;
}



.tab-content{
    padding-top: 20px;
    padding-left:10px;
    padding-right: 10px;
}
/* .input-group button{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: solid 1px #ddd !important;
}

.input-group .input-group-addon{
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
} */




/* .btn-group button{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: solid 1px #ddd !important;
}

.btn-group a:first-child, .btn-group button:first-child{
    border-bottom-left-radius: 32px !important;
    border-top-left-radius: 32px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: solid 1px #ddd !important;
}

.btn-group button:last-child{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 32px !important;
    border-top-right-radius: 32px !important;
    border: solid 1px #ddd !important;
} */

.mobileFooter  {
    height:"46px";
    background-color:"white";
    padding-top:"0px";
    position: "fixed";
    left:"0";
    bottom: "0";
    border-top:"solid 1px #ddd";
    width: "100%"}

.styles_modal__gNwvD{
    border-radius: 10px;
}

/* .pagination .active{
    background: var(--primary-clr)
} */

/* .btn-primary:hover, .btn-default:hover, .btn-success:hover, .btn-primary:focus, .btn-default:focus, .btn-success:focus
,.btn-primary:active, .btn-default:active, .btn-success:active{
    color:var(--primary-clr) !important;
    border:solid 1px var(--primary-clr) !important;
    background-color: white !important;
} */

/* old css end*/

.overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    cursor: default;
}

.error-msg{
    color:red;    
}

svg{
    height: 24px;
    fill:#5f6368;
}

.header-pos{
    overflow: hidden;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    display: flex;
    padding:10px;
    background-color:white;
}

/* .header  */
.left{
    display: flex;
    width: 50%;
    justify-content: flex-start;
}

.right{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}

.left-100{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.right-100{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.left-20{
    display: flex;
    width: 20%;
    justify-content: flex-start;
}

.left-30{
    display: flex;
    width: 30%;
    justify-content: flex-start;
}

.right-30{
    display: flex;
    width: 30%;
    justify-content: flex-end;
}

.left-70{
    display: flex;
    width: 70%;
    justify-content: flex-start;
}

.right-70{
    display: flex;
    width: 70%;
    justify-content: flex-end;
}

.right-80{
    display: flex;
    width: 80%;
    justify-content: flex-end;
}

.logo img{
    height: 44px;
    cursor: pointer;
}

.header-pos svg{
    height: 24px;
    fill:#5f6368;
}

.header-pos svg:hover{
    /* height: 24px; */
    /* fill:rgba(110, 45, 144, 1); */
    fill:white;

}

.header-pos .search{
    margin:0px 15px;
}

/* .header  */
.img-btn{
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color:var(--secondary-btn-clr);
    height: 46px;
    width:46px;
    cursor: pointer;
    transition: all .5s;
}

.header-pos .left .img-btn{
    margin-right: 15px;
}

.header-pos .right .img-btn{
    margin-left: 15px;
}

/* .header  */
.img-btn:hover{
    /* background-color: #ffe6ff; */
    /* var(--primary-clr); */
    background-color:var(--primary-clr);
    /* R-110 / G-45 / B-144 */
}

/* .header  */
.img-btn:hover svg{
    /* background-color: #ffe6ff; */
    /* rgba(110, 45, 144, 1); */
    fill:white;
    /* R-110 / G-45 / B-144 */
}

.header-pos .menu-overlay {
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    cursor: default;
}

.menu-left{
    height: 100%;
    /* width: 360px; */
    position: fixed;
    z-index: 3000;
    left: -360px;
    top: 0;
    
    transition: all .5s;
    display: flex;
}


/* z-index: 1000; */
    

.menu-right{
    height: 100%;
    /* width: 260px; */
    position: fixed;
    /* z-index: 3000; */
    z-index: 1000;
    right: -360px;
    top: 0;
    /* overflow: auto; */
    transition: all .5s;
    display: flex;
}

.menu-mobile-user{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px var(--btn-border-clr);
    padding: 10px;
    margin-bottom: 10px;
}

.menu-mobile-user .avatar-circle{
    align-self: auto;
    margin-bottom: 10px;
}

.menu-content{
    background-color: white;
    width: 260px;
    overflow: auto;
}

.menu-content::-webkit-scrollbar {
    width: 5px;
}

.menu-content::-webkit-scrollbar-thumb {
    /* background: #666;  */
    background: silver;
}

.menu-close-button{
}

.menu-logout-footer{
    position: fixed;
    bottom: 0;
    width: 260px;
    padding: 10px;
    text-align: center;
    background: white;
}


.menu-show-left{
    left: 0px;
}

.menu-show-right{
    right: 0px;
}

.menu-item{
    display: flex; 
    flex-direction: row; 
    flex-grow: 1; 
    align-items: center;
    padding: 10px;
    height: 48px;
    font-size:14px;
    cursor: pointer;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    margin-right: 5px;
    /* transition: all .5s; */
}

.menu-item-text{
    width: 85%;
}

.menu-item svg{
      height: 22px;
      margin-right: 10px;
}

.menu-item:hover{
background-color: var(--secondary-btn-clr);
color: var(--primary-clr);
}

.menu-item:hover svg{
fill: var(--primary-clr);
}

.menu-item-selected{
    background-color: var(--secondary-btn-clr);
    color: var(--primary-clr);
}

.menu-item-selected-child-items{
    border-bottom-right-radius: 0px;
}

.menu-item-selected svg{
    /* fill: var(--primary-clr); */
    fill:var(--primary-clr);
}

.menu-item-child-container{
    background-color: var(--secondary-btn-clr);
    margin-right: 5px;
    border-bottom-right-radius: 32px;
}

/* .menu-item-child{
    display: flex; 
    flex-direction: row; 
    flex-grow: 1; 
    align-items: center;
    padding: 10px;
    height: 48px;
    font-size:14px;
    cursor: pointer;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    margin-right: 5px;
    transition: all .5s;
} */

.menu-item-child:hover{
background-color: var(--primary-clr);
/* background-color:rgba(241, 243, 244, 1);; */
color: white;
}

.menu-item-child:hover svg{
fill: white;
}




.menu-item-child-selected{
    /* background-color:rgba(241, 243, 244, 1);; */
    /* background-color: var(--secondary-btn-clr); */
    /* border-bottom-right-radius: 0px; */
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    background-color: var(--primary-clr);
    color: white;
    /* color: var(--primary-clr); */
}

.menu-item-child-selected svg{
/* fill: var(--primary-clr); */
fill: white;

}



.menu-logo{
text-align: center;
padding: 10px;
border-bottom: solid 1px rgba(241, 243, 244, 1);
margin-bottom: 5px;
/* cursor: pointer; */
}

.menu-logo img{
height: 46px;
}

.menu-search{
    margin-left:15px;
    margin-right:  10px;
}

.menu-user{
    padding:10px;
    text-align: center;
    margin: auto
}

.menu-user .avatar-circle{
    margin: auto;
    margin-bottom: 10px;

}
.store-selection-list{
    height: 400px;
    overflow-y: auto;
}

.store-selection-list .list-row{
padding: 10px;
margin: 10px 0px;
cursor: pointer;
}

.store-selection-list .list-row:hover{
    background-color:  rgba(241, 243, 244, 1);
    border-radius: 10px;
}

.store-selection-list .row-title{
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 5px;
    cursor: pointer;
}

.store-selection-list .row-sub-text {
    font-size: 14px;
    cursor: pointer;
}

/* Input Start */
.input-rounded{
    border-radius: 32px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 100;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    height: 46px;
    background-color: var(--secondary-btn-clr)
    /* background-color: rgba(241, 243, 244, 1); */
}

.input-rounded span {
    margin-top: 8px;
    margin-right: 10px;
}

.input-rounded svg{

}

.input-rounded input {
    padding: 6px;
    padding-left: 28px;
    width: 100%;
    background-color: transparent;
    resize: none !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    border-radius: 32px;
}

.input-left-rounded{
    border-top-left-radius: 35px;
    border-bottom-left-radius:35px;
}

/* Input End */

/* Button start */

.button-primary{
    /* border-radius: 32px; */
    width: 100%;
    height: 46px;
    background-color:var(--primary-clr);
    border:none;
    color: white;
    margin-bottom: 10px;
    display: flex;
    justify-content: center
}

.button-primary svg{
    margin: 10px 0px;
    fill:white;
}

.button-primary:hover{
    color:var(--primary-clr);
    border:solid 1px var(--primary-clr);
    background-color: white;
}

.button-primary:hover svg{
    fill:var(--primary-clr);
}

.button-processing{
    color:var(--primary-clr);
    border:solid 1px var(--primary-clr);
    background-color: white;
}

.button-secondary{
    /* border-radius: 32px; */
    width: 100%;
    height: 46px;
    background-color:var(--secondary-btn-clr);
    border:solid 1px var(--secondary-btn-clr);
    margin-bottom: 10px;
    display: flex;
    justify-content: center
}

.button-secondary svg{
    margin: 10px;
}

.button-secondary:hover{
    color:var(--primary-clr);
    border:solid 1px var(--primary-clr);
    background-color: white;
}

.button-secondary div, .button-primary div, .button-secondary i, .button-primary i{
    margin: 12px 0px;
}

.button-selected {
    background-color: var(--primary-clr);
    color:white;
    border-color: var(--primary-clr);
}

.button-selected svg{
    fill:white;
}

.button-link{
    height: 46px;
    width: 100%;
    cursor: pointer;
}

.button-rounded{
    border-radius: 32px;
}

.button-currency{
    border-radius: 10px;
    background-color: var(--secondary-btn-clr);
    height: 70px;
    width:70px;
    margin-right: 10px;
    border:solid 1px var(--btn-border-clr);
    margin-bottom: 10px;
}

.button-currency:hover{
   border-color:var(--primary-clr);
   color:var(--primary-clr);
   background-color: white;
}

#bootstrap-overrides .button-lg{
    height:46px;
}


#bootstrap-overrides .button-flat{
    border-radius: 0px;
    background: var(--secondary-btn-clr);

}


#bootstrap-overrides .button-left-rounded{
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    background: var(--secondary-btn-clr);

}

#bootstrap-overrides .button-right-rounded{
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    background: var(--secondary-btn-clr);

}

#bootstrap-overrides .button-border{
    border:solid 1px var(--btn-border-clr);
}

#bootstrap-overrides .button-selected {
    background-color: var(--primary-clr);
    color:white;
    border-color: var(--primary-clr);
}

#bootstrap-overrides .button-selected svg{
    fill:white;
}

/* Button end */




.button-KOT{
    height: 66px;
}

.buttton-printbill{
    height: 66px;
}







.pay-btn {
    height: 60px;
    padding: 10px;
    background-color: var(--primary-clr);
    color:white;
    padding:0px 32px;
    border-radius: 32px;
    border:none;
    width: 100%;
    font-size: 20px;
    transition: all .5s;
    cursor: pointer;
}

.sales-return{
    background-color: orange;
}


.sales-edit {
    background-color: blue;
}

.pay-btn:hover {
    color: var(--primary-clr);
    background-color:white;
    border: solid 1px var(--primary-clr);
    cursor: pointer;
}

.pay-btn-return{
    height: 46px;
    padding: 10px;
    background-color: var(--primary-clr);
    color:white;
    padding:0px 32px;
    border-radius: 32px;
    border:none;
    width: 100%;
    font-size: 20px;
    transition: all .5s;
    cursor: pointer;
}

.pay-btn-return:hover {
    color: var(--primary-clr);
    background-color:white;
    border: solid 1px var(--primary-clr);
    cursor: pointer;
}

.sales-footer{
    height: 80px; 
    background-color: white; 
    padding: 10px 10px 10px 10px; 
    position: fixed; 
    left: 0px; 
    bottom: 0px; 
    /* border-top: 1px solid rgb(221, 221, 221);  */
    width: 100%;
}

.sales-footer-options{
    display:flex;
}

.sales-footer-option-left{
    display: flex;
    width: 50%;
    -ms-flex-pack: end;
    justify-content: flex-start;
}

.sales-footer-option-right{
    display: flex;
    width: 50%;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

/* .sales-footer-option-left .img-btn{
    margin-right: 15px;
} */

/* .sales-footer-option-right .img-btn{
    margin-left: 15px;
} */

.sales-footer-btn{
    width: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sales-footer-btn-label{
    font-size: 10px
}

.rbt-input-main{
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
}



/* .btn-flat{
    background: var(--secondary-btn-clr);
    border-top:solid 1px var(--btn-border-clr);
    border-bottom:solid 1px var(--btn-border-clr);    
    border-left: none;
    border-right: none;
    height: 46px;
} */

#bootstrap-overrides .btn-nocurve{
        border-radius: 0px !important;
        border: 1px solid #ccc !important;
    }

#bootstrap-overrides .btn-nocurve:hover{
    border-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .btn-left-nocurve{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .btn-left-nocurve:hover{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .btn-left-nocurve{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .btn-right-nocurve:hover{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: 1px solid #ccc !important;
}

#bootstrap-overrides .small{
    height: 30px;
    min-width: 30px;
    padding-top: 2px;
}

.sales-prodcut-search-section {
    display: flex;
}
.sales-qty{
    height: 30px;
    width: 66px;
    border:solid 1px var(--btn-border-clr);
    text-align: center;
    
}

/* .sales-remove{
    margin-top: 20px;
} */

.sales-remove svg{
    fill:rgb(153, 153, 153);
    margin-top:22px;
    cursor: pointer;
}

.sales-lt{
    padding-top:5px;
}

.info{
    background: var(--secondary-btn-clr);
    border-radius: 10px;
    padding:10px;
    text-align: left;
    margin-bottom: 10px;
}

.info-header{
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px;
    /* border-bottom: solid 1px var(--btn-border-clr) */
}


/* quick add start */

.quick-add-container{
    display:flex;
    position: fixed;
    width: 500px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5000;
}

.quick-add{
    background-color:white; 
    border-radius: 10px;
    padding:10px;
    width: 100%;
}
.quick-add-close-button{
    z-index: 5000;
}
.quick-add-header{
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.quick-add-content{
    display: flex;
}

.quick-add-options{
    display: flex;
    flex-flow: column;
    padding-right: 10px;
    width: 50%;
    overflow: auto;
    height: 450px;
}

.quick-add-options::-webkit-scrollbar {
    width: 5px;
}

.quick-add-options::-webkit-scrollbar-thumb {
    /* background: #666;  */
    background: silver;
}

.quick-add-options-header{
    padding:10px;
    font-weight: bold;
}
/* quick add end */




/* sales payment start */

/* .style={{ overflowX: "scroll", overflow: "scroll", height: "80vh" }} */
.sales-payment-close-button{
    top: 10px;
    position: absolute;
    z-index: 500;
    right: 15px;
}

.sales-payment{
    border-radius: 10px;
    background: white;
    padding: 10px 0px;
    position: absolute;
    z-index: 600;
    top:50px;
    margin:10px 20px 0px 20px;
    padding-top:20px;
    transition: all .5s;
}

.payment-left-section{
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.add-payment{

}
/* style={{ marginTop: "5px", marginLeft: "15px" }} */
.cash-payment-mode{
    margin-top:10px;
    margin-left:15px;
}

.payment-right-section{
    min-height: 60vh;
    margin-right: 15px;
    /* margin-top:15px; */
}

/* style={{ marginTop: "20px", marginBottom: "20px" }} */
/* style={{ fontSize: "35px" }} */
.payment-right-section-header label{
    font-size: 30px;
    padding-top:15px;
    padding-bottom:20px;
}

.payment-right-section-paymentmode{
    display:flex;
    padding-bottom: 15px;
}

.payment-right-section-paymentmode label{
    font-size: 18px;
    padding-top:15px;
    padding-left: 15px;
}

.payment-right-section-paid-amt {
    padding-bottom: 10px;
}


.payment-right-section-paid-amt label{
    font-size: 18px;
}

.payment-right-section-paid-amt .left label{
    padding-top: 10px;
}

.payment-right-section-bal-amt{
    padding-bottom: 10px;
}

.payment-right-section-bal-amt label{
    font-size: 18px;
}
/* style={{ display: "flex", justifyContent:"spaceBetween" }} */
.payment-right-section-due-date{
    display: none;
}
/* sales payment end */

/* Login start */
.login{
    width:100%;
    height: 100vh;
    /* background:#F7F7F7; */

    /* background-image:linear-gradient(to right, rgb(110, 45, 144) 0,rgb(110, 45, 144) 33%,rgb(144,39,142) 33%, rgb(144,39,142) 66%, rgb(236,28,36) 66% , rgb(236,28,36)100%);; */
    /* background-image:linear-gradient(to right, rgb(110, 45, 144) , rgb(144,39,142),rgb(144,39,142), rgb(144,39,142),rgb(236,28,36));; */
}

.login-logo{
    padding-top: 30px;padding-bottom: 40px;text-align: center;
}

.align-center{
    text-align: center;
}

.signup-logo
{
text-align: center;
width: 100%;
}

.signup-header{
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;

}

.signup-container{ 
    width: 350px;
    background-color: white;
    border-radius:10px;
    padding: 20px;
    margin: auto;
}

.signup-container-mobile{
    width: 350px;
    background-color: white;
    border-radius:10px;
    padding: 20px;
    margin: auto;
}

.login-container{ 
    width: 350px;
    background-color: white;
    border-radius:10px;
    padding: 20px;
    margin: auto;
    border:solid 1px var(--btn-border-clr) ;
    text-align: center
}

.login-container-mobile{
    width: 350px;
    background-color: white;
    border-radius:10px;
    padding: 20px;
    margin: auto;
    /* border:solid 1px var(--btn-border-clr) ; */
    text-align: center;
}

.login-container-mobile img{
    margin: 30px 0px 40px 0px;

} 

.login-container img{
    
    margin: 30px 0px 40px 0px;
}

/* Login end */





.right-flat{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

}

/* inventory start */

/* .inventory-button-bar{
padding-bottom: 10px;
} */

/* inventory end */

#bootstrap-overrides .invoice{
    margin: 0px;
    background: white;
    padding: 10px;
    border-radius: 10px;
}

.account-expired{
    width: 100%;
    background-color:red;
}

.account-expiring{
    width: 100%;
    background-color: orangered;
    border-radius: 10px;
    /* margin: -20px; */
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    color: white;
    text-align: center;
}


/* Customer dashboard start*/
.customer-info-section{
    display: flex;
}

.customer-info-section .info-box{
    background: white;
    border-radius: 10px;
    padding:10px;
    display: flex;
}
/* Customer dashboard end */


/* Report dashboard start*/
.dashboard-header{
    display: flex;
    margin-bottom: 10px;
}

.dashboard-header-title{
    font-size: 32px;
}
.dashboard-header-options{
    display: flex;
    /* justify-content: end; */
}

.dashboard-header-options .input-ctrl .form-control{
    height:46px;
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
}

.dashboard-summary{
    display: flex;
    margin-top:20px;
    flex-wrap: wrap;

    /* justify-content: space-between; */
}

.info-box{
    display: flex;
    padding:20px;
    border-radius: 10px;
    /* width: 250px; */
    /* margin-right: 20px; */
    margin-bottom: 20px;
}

.info-box-icon{

    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.18);
    margin-right: 20px;
}

.info-box-icon svg{
    fill: white;
    margin-top: 11px;
    margin-left: 14px;
}

.info-box-content{

}

.info-box-text{
    color: white;
    font-size: 18px;
}

.info-box-number{
    color: white;
    font-size: 24px;
}

.info-box-number-subtext{
    color: white;
    font-size: 14px;
}

.info-blue{
    box-shadow: 0 6px 20px 0 rgba(38,198,218,.5)!important;
    background: linear-gradient(45deg,#0288d1,#26c6da)!important;
}
.info-red{
    background: linear-gradient(45deg,#ff5252,#f48fb1)!important;
    box-shadow: 0 6px 20px 0 rgba(244,143,177,.5)!important;
}
.info-yellow{
    box-shadow: 0 6px 20px 0 rgba(255,202,40,.5)!important;
    background: linear-gradient(45deg,#ff6f00,#ffca28)!important;
}
.info-green{
    background: linear-gradient(45deg,#43a047,#1de9b6)!important;
    box-shadow: 0 6px 20px 0 rgba(29,233,182,.5)!important;
}

.blue{
   
    background-color: rgb(38,198,218);
}

.red{
    background-color:#ff5252;

}
.orange-red{
    background-color:#ff6f00;
}
.green{
    background-color:#43a047;
}

.dashboard-details{
    display: flex;
    margin-top:20px;
    flex-wrap:wrap;
}

.dashboard-box{
    background: white;
    border-radius: 10px;
    padding:10px;
    margin-bottom: 10px;
    height: 450px;
    /* margin-right: 1%; */
    /* flex: 32%; */
    /* max-width: 32%; */
    
}

.dashboard-box-wide{
    background: white;
    border-radius: 10px;
    padding:10px;
    margin-right: 1%;
    /* margin-right: 20px;
    margin-bottom: 20px; */
    /* width:66%; */
    margin-bottom: 10px;
    flex: 65%;
  max-width: 65%;
  /* padding: 0 4px; */
}

.dashboard-box-header{
    display: flex;
    font-size: 18px;
    margin-bottom: 10px;
}
.dashboard-box-body{
    height: 300px;
}
.dashboard-box-footer{
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.chart{
    height:250px;
}

.table-responsive{
    height: 310px
}
/* Report dashboard end */


@media print
{

    .no-print
    {
        display: none !important;
        height: 0;
    }


    .no-print, .no-print *{
        display: none !important;
        height: 0;
    }
}


.customer-dashboard-details{
    background: white;
    padding: 10px;
    border-radius: 10px;
    /* margin-top:20px; */
}

.customer-dashboard-details ul{
    margin-bottom: 10px;
}

.customer-dashboard-details-tab-section{
    height: 330px;
    padding: 10px;
}
/* 
.box-body{
    position: absolute;
    top:100px;
    z-index: 6000;
} */


.header-margin{
    margin-top: 76px;
}



/* Input control start */

.input-ctrl{
    margin-bottom: 10px;
}

.textarea{
    border-radius: 10px;
}
/* Input control end */


.number{
    text-align: right;
}

.mailbox-attachment-icon img{
    height: 50px;
}
.pincode-product-images{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    
}

.pincode-product-image{
    border: solid 2px var(--secondary-btn-clr);;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative; 
}

.pincode-product-image img{
    height: 200px;
}

.pincode-product-image-upload{
    height: 200px;
}

.pincode-product-image-img {
    flex: 1; /* Allow the image div to take up available space */
    display: flex; /* Use flexbox for the image */
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
}

.pincode-product-image-remove {
    position: absolute; /* Position it absolutely within the parent */
    bottom: 10px; /* Distance from the bottom */
    right: 10px; /* Distance from the right */
    
}

.box-header label{
    font-size: 20px;
}

.report-line-item{
    margin: 10px 0px;
    padding-bottom: 10px;
    width: 100%;
    border-bottom: dashed 1px #5f6368;
}

.inventory-footer{
    height: 60px;position: fixed;bottom: 0px;width:100%;right:0;background-color: #F0F2F5;left:0;
}

.inventory-footer-sub{
    background-color: white;
    margin-left: 15px;
    margin-right: 15px;
    border-top: 1px solid rgb(221, 221, 221);
    display: flex;
    padding: 5px 10px;
    /* padding-left: 10px;
    padding-right: 10px; */
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

}

.inventory-footer-sub-50{
    width:50%;
}




.timePicker{
    display: flex;
    border: solid 1px var(--btn-border-clr);
    border-radius: 32px;
}

.timePicker input{
    border:none;
    border-radius: none;
}

.timePicker select{
    border:none;
    border-radius: none;
    width: 100px;
}

.timePicker svg{
    margin: 5px 5px 0px 5px;
}

.timePicker .seperator{
    margin-top: 4px;
    font-weight: bold;
    font-size: 18px;
}

.timePicker .input-group{
    border: solid 1px var(--btn-border-clr);
    border-radius: 32px;
    background: var(--secondary-btn-clr);
}

.uppercase{
    text-transform: uppercase;
}

.header-online-switch{
    align-items: center;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: var(--secondary-btn-clr);
    height: 46px;
    cursor: pointer;
    padding-top:5px;
    padding-right: 10px;
    padding-left: 10px;
    width: 134px;

}

.header-online-switch span{
    margin-right: 5px;
    text-transform: uppercase;
}
.header-online-switch .input-ctrl{
    margin-bottom: 0px;
}