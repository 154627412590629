.sales-sub-text {
    font-size: 10px;
}

.payment-container-left {
    background-color: white;
    margin-right: -10px;
    padding-top: 10px;
    padding-right: 10px;
    min-height: 82vh;
}

.payment-container-right {
    background-color: white;
    padding-top: 10px;
    /* padding-left:10px;
    padding-right: 10px; */
    min-height: 82vh;
}

.payment-container-section {
    min-height: 60vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 100px; /* height of your footer */
}

.footer1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
}


.pay-btn small {
    margin-left: 20px;
}

.pad {
    padding-top: 10px;
    padding-right: 10px;
    margin-top: 0px;
}





.item-list ::-webkit-scrollbar{
    display: none
}

.item-list-scroll { 
    height: 66vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.line-item-list-scroll-long {
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.line-item-list-scroll-short {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll2::-webkit-scrollbar {
    width: 5px;
}

.scroll2::-webkit-scrollbar-thumb {
    /* background: #666;  */
    background: silver;
}

.payment-section {
    min-height: 80vh;
    background-color: white;
    margin-top: 0px;
}

.product-selection-grid {
    /* display: flex;
    flex-wrap: wrap; */
    overflow-y: scroll;
    height: 66vh;
    /* padding-top: 5px; */
    /* padding-left:5px */
    /* max-width: 1000px; */
    /* margin: 0 auto; */
}

.category-selection-btn {
    padding: 5px;
    width: 94%;
    height: 100px;
    margin: 5px;
}

.category-selection-btn-selected {
    padding: 5px;
    width: 94%;
    height: 100px;
    margin: 5px;
    border: solid 2px #00a65a;
    background-color: #00a65a;
    color: white;
}
.category-selection-btn-selected:hover {
    padding: 5px;
    width: 94%;
    height: 100px;
    margin: 5px;
    border: solid 2px green;
    background-color: green;
    color: white;
}

.product-select-btn-text1 {
    color: #000;
    display: -webkit-box;
    margin: 4px;
    font-size: 14px;
    height: 3.6em;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-select-btn-text-full1 {
    color: #000;
    display: -webkit-box;
    margin: 4px;

    height: 3.6em;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .product-select-btn-text-full-product-name{
    font-size: 17px;
    margin: 0px;
    white-space: normal;
    height:70px;
    overflow: hidden;
    text-overflow: ellipsis;
} */

.product-select-btn-text-full-product-name {
    font-size: 17px;
    margin: 0px;
    white-space: normal;
    height: 70px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.product-select-btn-text-full-product-name1 {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px 5px;
    text-overflow: clip;
    box-sizing: border-box;
    overflow: hidden !important;
    overflow-wrap: unset;
    word-break: break-all;
    width: 100%;
    height: 100%;
    margin: 0;
}

.product-select-btn-text-full-product-price1 {
    height: 30px;
    font-size: 12px;
    margin: 0px;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-select-btn-text-name1 {
    width: 100%;
    height: 40%;
    margin: 1px;
    font-weight: bold;
    padding: 5px;
    box-sizing: border-box;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 5px;
    text-align: center;
}

.product-select-btn-text-name {
    /* height:95px; */
    font-size: 12px;
    /* color: #000; */
    display: -webkit-box;
    margin: 0px;
    /* height: 3.6em; */
    white-space: normal;
    font-weight: normal;
    text-align: center;
    line-height: 1.2em;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-select-btn-text-price1 {
    height: 40px;
    font-size: 12px;
    margin: 4px;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.photo {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

/* product container */
.product-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-left: 30px;
}

/* product variants-div */
@media screen and (max-width: 480px) {
    .product-variants-div .box-body .product-container .product-selection-btn {
        /* background: red; */
        width: 27%;
        height: 60px;
    }
}
.amount-quantity-div {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 3px 0px;
}

@media screen and (max-width: 500px) {
    .styles_overlay__CLSq- {
        /* background: red; */
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: white;
    }
    .styles_modal__gNwvD {
        padding-left: 0;
        padding-right: 0;
    }
    .styles_overlay__CLSq- {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    .styles_modalCenter__L9F2w {
        width: 100%;
        height: 100%;
        margin: 0;
    }
    .box-footer {
        bottom: 0;
        position: fixed;
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .content-wrapper {
        min-height: 100% !important;
    }
    .box-body {
        height: 80vh !important;
        overflow-y: scroll;
    }
    .btn-rounded {
        width: 45%;
        border-radius: 20px !important;
        margin: 0 5px;
    }
    .newHeight {
        height: 60vh !important;
    }
    .nav-tabs {
        display: flex !important;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 10px;
        overflow-y: scroll !important;
    }
    .btn-rounded2 {
        width: 90%;
        border-radius: 20px !important;
    }
    .errorFooter {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
}



.item-list-scroll2 {
    height: 66vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
}