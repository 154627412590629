/* .filter-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 52px;
    left: 0;
    background-color:white;
    overflow-x: hidden;
    transition: 0.5s;
  } */

  .filter{
    transition: 0.5s;
  }

  .filter-overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
    cursor: default;
   
}

  .filter-content{
    height: 100%;
    width: 80%;
    position: fixed;
    z-index: 3000;
    right: 0;
    top: 0;
    /* padding:20px 20px; */
    background-color: white;
    overflow: auto;
  }

  .filter-header{
    position: fixed;
    width:80%;
    padding: 14px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    border-bottom: 1px solid #dee3e7;
    background-color: white;
  }

  .filter-title{
    line-height: 18px;
    text-align: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin: 0;
    color: #101928;
    font-size: 20px;
    font-weight: 300;
  }

  .filter-close{

  }

  .filter-body{
    margin-top: 60px;
    margin-bottom: 60px;
    padding:10px;
  }

  .filter-footer {
    position: fixed;
    /* left: 0; */
    bottom: 0;
    width: 80%;
    padding:10px;
    background-color: white;
    text-align: center;
    z-index: 3001;
 }

 .filter-button-clearall
 {
     width:50%;
     height:46px;
     border:none;
     border-radius: 50px;
     background-color:#f7f7f8;
     outline-style: none;
 }

 .filter-button-apply
 {
     width:50%;
     height:46px;
     border:none;
     color: white;
     border-radius: 50px;
     background-color: #00a65a;
     outline-style: none;
 }