
  .productselection-prodcut-image {
    width: 110px;
    height: 110px;
    overflow: hidden;
  }

  .productselection-prodcut-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .productselection-prodcut-product{
    width: 300px;
  }

.productselection-prodcut-price{
    text-align: right;
}