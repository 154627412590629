.form-section{
    /* padding-top: 20px; */
    /* padding-bottom: 10px; */
    text-align: left;
    /* border-bottom: solid 1px var(--btn-border-clr); */
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    /* color: var(--primary-clr) */
}

.form-section-content{
    background-color: white;
    margin-top: 10px;
    padding-bottom: 20px;
    /* border-bottom: solid 1px var(--btn-border-clr); */
}