
.pagesection{
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */

}

.pagesection-header{
    font-family: 'Nunito';
    font-size: 18px;
    padding: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
}


.pagesection-body{
    padding:10px;
}