.kot-customer-display-section{
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
}

.kot-customer-display-pending-title{
    font-size: 30px;
    text-transform: uppercase;
}

.kot-customer-display-ready-title{
    font-size: 30px;
    text-transform: uppercase;
}

.kot-customer-display-pending{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    background-color: #488AE0;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
}
.kot-customer-display-ready{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    background-color: #BDECB7;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
}