.sales-mobile-header {
    /* background-color: white; */
    position: fixed;
    width: 100%;
    top: 56px;
    left: 0;
    right: 0;
    z-index: 99;
}

.sales-mobile-header-buttons {
    background-color: white;
    width: 20%;
    border: none;
    height: 75px;
    text-decoration: none;
    outline-style: none;
    border-bottom: 1px solid rgb(221, 221, 221);
}

.sales-mobile-header-buttons-customer-selected {
    color:var(--primary-clr);
}


.sales-mobile-header-buttons-selected {
    background-color: white;
    width: 20%;
    border: none;
    height: 75px;
    border-bottom: solid 4px var(--primary-clr);
    outline-style: none;
}

.sales-mobile-header-buttons div {
    font-size: 10px;
}

.sales-mobile-header-buttons-selected div {
    font-size: 10px;
}

.sales-mobile-list {
    height: 100%;
    padding-top: 70px;
    /* background-color: white; */
    /* padding-left: 10px;
    padding-right: 10px; */
}

.sales-mobile-list-white {
    height: 100%;
    padding-top: 75px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.sales-mobile-header-button-back {
    background-color: white;
    border: none;
    height: 60px;
    width: 40px;
    text-decoration: none;
    outline-style: none;
    padding-top: 20px;
    padding-left: 10px;
}

.sales-mobile-header-button-clear {
    background-color: white;
    border: none;
    height: 60px;
    width: 40px;
    text-decoration: none;
    outline-style: none;
    padding-top: 20px;
    padding-left: 10px;
}

.sales-mobile-header-button-add {
    background-color: white;
    border: none;
    height: 60px;
    width: 40px;
    text-decoration: none;
    outline-style: none;
    padding-top: 17px;
    padding-right: 10px;
}

.sales-mobile-header-input {
    border: none;
    margin-top: 10px;
    height: 40px;
    background-color: #f7f7f8;
    border-radius: 50px;
    width: 100%;
    padding: 10px;
    outline-style: none;
}

.sales-mobile-footer {
    height: 60px;
    background-color: white;
    padding-top: 7px;
    position: fixed;
    left: 0px;
    bottom: 0px;
    border-top: 1px solid rgb(221, 221, 221);
    width: 100%;
}

.button-category {
    overflow: hidden;
    width: 90%;
    /* height: 180px; */
    height: 110px;
    margin: 7px 5px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    /* background-color: white; */
    /* background: red; */
    border: 1px solid var(--btn-border-clr);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
}

.button-category-selected{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    background-color: var(--primary-clr);
    color:white;
    border-color: var(--primary-clr);
}

.button-category:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.button-product-category-image {
    width: 100%;
    height: 110px;
    /* max-height: 100px;
    min-height: 100px; */
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white;
}
.button-product-category-image img {
    height: 100%;
    height: 100%;
}

.button-category-sub-img {
    position: absolute;
    height: 40px;
    width: 100%;
    padding: 2px;
    color: white;
    text-align: center;
    line-height: 20px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
}

.button-product {
    overflow: hidden;
    width: 21%;
    min-width: 21%;
    height: 180px;
    margin: 7px 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid var(--btn-border-clr);
    position: relative;
    cursor: pointer;
}

.button-product:hover{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.button-product-image {
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
}

/* .button-product-image:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
} */

.button-product-image img {
    width: 100%;
    height: 100%;
}

.button-category-sub-text {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: bold;
    width: 100%;
    padding: 5px;
}
.btn-category-img-div {
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border-radius: 5px;
}
.btn-category-img-div img {
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    border-radius: 5px;
}

.button-sub-text-text {
    text-align: center;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    padding: 7px;
    font-weight: bold;
}

.button-sub-text-price {
    text-align: center;
    align-self: flex-start;
    width: 100%;
    /* margin: 6px; */
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    word-wrap: break-word;
    overflow: hidden;
    font-weight: 600;
    color: black;
    background-color:var(--secondary-btn-clr);
     /* rgba(0, 0, 0, 0.1); */
}

.button-sub-text-img {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    /* align-items: center; */
    margin-bottom: 5px;
    color: rgb(43, 42, 42);
    font-weight: bold;
    word-wrap: break-word;
    overflow: hidden;
    box-sizing: border-box;
    overflow: hidden;
}

/* .button-selected {
    border-color: var(--primary-clr);
    #00a65a;
    border-width: 2px;
} */

.option2 {
    width: 21%;
    display: inline-block;
}

/* mobile view code for product-selection-btn */
.product-selection-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5px;
    box-sizing: border-box;
    align-content: center;
}
.product-selection-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px !important;
    width: 30%;
    height: 120px;
    border: 2px solid lightgray;
    box-sizing: border-box;
    overflow: hidden;
    margin: 5px;
    box-sizing: border-box !important;
    overflow: hidden !important;
    background: white;
    position: relative;
}

.image-div {
    display: flex;
    width: 100%;
    height: 65px;
    box-sizing: border-box;
    overflow: hidden;
    overflow: hidden !important;
}
.product-select-btn-text-full-product {
    /* display: flex;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    padding: 3px 3px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: clip;
    box-sizing: border-box;
    width: 100%;
    overflow-wrap: unset;
    height: 80px;
    margin-top: auto;
    text-overflow: clip;
    word-break: break-all; */

    /* display: flex; */
    font-weight: bold;
    vertical-align: middle;
    width: 100%;
    padding: 3px;
    box-sizing: border;
    height: auto;
    max-height: 80px;
    overflow: hidden;
    text-align: center;
    margin-top: auto;
    word-break: break-all;
}

.product-select-btn-text-full-price {
    background-color: white;
    text-align: center;
    align-self: flex-end !important;
    margin: auto 0 0px 0;
    width: 100%;
    /* height: 20px; */
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* image with name of product */
.product-select-btn-img-text-full-product {
    text-align: center;
    width: 100%;
    height: 24px;
    overflow: hidden;
    /* font-size: 13px; */
    font-weight: bold;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    white-space: wrap;
    /* line-height: 13px; */
    padding: 0px 3px;
}

/* user selected product list */
.product-list-box {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box;
}

.product-list-box .item {
    display: flex;
    padding: 2px;
    margin: 7px 0;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color:var(--secondary-btn-clr);
    border-radius: 10px;
    overflow: hidden;
}
.product-list-box .item:first-child {
    margin-top: 0 !important;
}

.item-img {
    flex: 1;
    /* background-color: pink; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-info {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding: 2px;
}

.item-price-and-Remove-item {
    flex: 1;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    height: 100%;
    overflow: hide;
    padding-left: 2px;
}
.product-quantity-selection-div {
    display: flex;
    margin-top: 4px;
}
.product-quantity-selection-btn {
    width: 23px;
    height: 23px;
    font-weight: bold;
    margin: 5px 0;
    outline: none;
    cursor: pointer;
    color: black;
    border: 1px solid black;
    align-items: center;
}
.inputDiv-product-quantity {
    outline: none;
    font-size: 13px;
    width: 70px;
    max-width: 100px;
    height: 23px;
    border: 1px solid black;
    margin: 5px 3px;
    text-align: center;
}

@media screen and (max-width: 320px) {
    
    .product-selection-btn {
        width: 45%;
    }
}
@media screen and (max-width: 280px) {
    
    .product-selection-btn {
        width: 45%;
    }
}



.button-category2 {
    overflow: hidden;
    width: 120px;
    min-width: 120px;
    height: 155px;
    margin: 7px 5px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    /* background-color: white; */
    /* background: red; */
    border: 1px solid var(--btn-border-clr);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
}

.button-product2 {
    overflow: hidden;
    width: 18%;
    min-width: 165px;
    width: 120px;
    min-width: 120px;
    height: 155px;
    margin: 7px 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid var(--btn-border-clr);
    position: relative;
}


/* new css  26march*/
.sales-container{
    margin: 76px 10px 0px 10px
}



#bootstrap-overrides .sales-productsearch-btns button:hover{
    color: white;
    background-color: var(--primary-clr);
    border-color: var(--primary-clr);
}


.sales-productsearch-btns button:hover svg{
    fill: white;
    background-color: var(--primary-clr)
}

.sales-product-list-item{
    display: flex;
    border-bottom: solid 1px var(--btn-border-clr);
    width: 100%;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top:10px;
}

.sales-product-list-item-maintext{
    font-weight: bold;
    font-size: 18px;
    display: flex;
}


.sales-product-tab {
    /* min-height: 76vh; */
    background-color: white;
    border-radius: 10px
}

.sales-item-list {
    /* height: 76vh; */
    background-color: white;
    border-radius: 10px;
    padding:10px;
    
    /* background-color:red */
}


.sales-selected-line-items{

}

.sales-selected-line-item{
    display: flex;
    border-bottom:1px solid #d2d6de;
    padding:10px 0px;
}


.sales-selected-line-item-content{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sales-selected-line-item-content-qty{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.sales-selected-line-item-avatar{
    cursor: pointer;
}

.sales-qty-control{
    display: flex;
}

.sales-qty-control button{
    width: 46px;
}

.sales-qty-control input{
    width: 50px;
}

.sales-selected-line-item-content-qty4{
    margin-right: 5px;
}

.sales-selected-line-item-avatar img{
    margin-right: 5px;
}
.sales-footer-btn:hover{
    color: var(--primary-clr)
}


 /* new mobile billing screen - start  */
 .sales-header{
    display: flex;
    padding: 10px;
    position: fixed;
    top:0px;
    background-color: white;
    justify-content:space-between;
    width: 100%;
    box-shadow: 0 5px 5px -5px var(--btn-border-clr);
    z-index: 101;
}

.sales-header-title{
    width: 85%;
    font-size: 20px;
    padding-top: 9px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.sales-header-back{
    width: 15%;
}

.searchbar{
    width: 85%;
}

/* .sales-header input{
    
    height: 44px;
} */

.sales-content{
    margin-top: 66px;
    padding: 10px;
    /* background: white; */
    /* min-height: 80vh; */
}

.sales-content .button-secondary{
    margin-bottom: 10px;
}

.dummyBtns{
    width: 24%;
    height: 120px;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    word-break: break-all;
    overflow: hidden;
}

.categories{
   
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.category{
    width: 24%;
    height: 150px;
    border: solid 1px var(--btn-border-clr);
    border-radius: 10px;
    margin-bottom: 10px;
    /* padding: 2px; */
    justify-content: center;
    display: flex;
    align-items: center;
    /* word-break: break-all; */
    overflow: hidden;
    cursor: pointer;
    flex-direction: column;
    background-color: white;
}

.category-name{
    font-weight: bold;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   
}

.category-image{
    height: 90px;

}

.category-image img{
    height: 100%;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
}

.category-image-name{
    font-weight: bold;
    font-size: 13px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* background-color: var(--primary-clr); */
    background-color: var(--secondary-btn-clr);
    text-overflow: ellipsis;
    overflow: hidden;
    /* color: white; */
    text-align: center;
    width: 100%;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.products{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product{
    width: 24%;
    height: 150px;
    border: solid 1px var(--btn-border-clr);
    border-radius: 10px;
    margin-bottom: 10px; 
    text-align: center;
    background-color: white;
}

.product-name{
    /* word-break: break-all; */
    padding: 3px;
    
    font-size: 13px;
    height: 130px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-price{
    background-color:  rgba(0, 0, 0, 0.5);
    color: white;
    /* background-color: var(--secondary-btn-clr); */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 20px;
    font-weight: bold;
    font-size: 13px;
}

.product-image{
    height: 70px;
}

.product-image img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        vertical-align: middle;
}

.product-image-name{
    font-weight: bold;
    font-size: 13px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* background-color: var(--secondary-btn-clr); */
    background-color:  rgba(0, 0, 0, 0.5);
    color: white;
    
    text-overflow: ellipsis;
    /* word-break: break-all; */
    overflow: hidden;
    /* color: white; */
    text-align: center;
    width: 100%;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.product-image-price{
    font-weight: bold;
    font-size: 13px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* background-color: var(--secondary-btn-clr); */
    background-color:  rgba(0, 0, 0, 0.5);
    color: white;
    
    text-overflow: ellipsis;
    overflow: hidden;
    /* color: white; */
    text-align: center;
    width: 100%;
    height: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.back{
    width: 24%;
    height: 150px;
    border: solid 1px var(--btn-border-clr);
    border-radius: 10px;
    margin-bottom: 10px; 
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.back svg{
    height: 25px;
}

.sales-new-mobile-footer{
    display: flex;
    padding: 10px;
    position: fixed;
    bottom:0px;
    background-color: white;
    justify-content:space-between;
    width: 100%;
    box-shadow: 0 -5px 5px -5px var(--btn-border-clr);
}

.salesreturn-negative-qty{
    padding: 10px;
    position: fixed;
    bottom: 55px;
    width: 100%;
    background: white;
}

.sales-new-mobile-footer .total-qty{
    /* width: 50%; */
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    background-color: var(--secondary-btn-clr);
    border-radius: 32px;
}

.sales-new-mobile-footer .pay-btn1{
    height: 46px;
    border-radius: 50px;
    /* background-color: var(--primary-clr); */
    color: white;
    /* width: 50%; */
    padding:10px 20px;
    display: flex;
    justify-content:space-between;
    font-size: 18px;
    font-weight: bold;
}

.sales-new-mobile-footer .button-rounded{
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: bold;
}

.newsale{
    background-color: var(--primary-clr);
}

.editsale{
    background-color: #0288d1;
}

.salereturn{
    background-color: #ff6f00;
}

.no-lineitem{
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.dummyLineItem{
    height: 100px;
    width: 100%;
}

.lineitem{
    display: flex;
    padding: 10px;
    background-color: var(--secondary-btn-clr);
    margin-bottom:10px ;
    border-radius: 10px;
    font-size: 13px;
    justify-content: space-evenly;
    width: 100%;
}

.lineitem-content{
    width: 85%;
}

.lineitem-delete{
    width: 15%;
}

.lineitem-product-name{
    font-weight: bold;
}
.lineitem-row
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.lineitem-qty{
    border:solid 1px black;
    width: 122px;
    border-radius: 30px;
}

.lineitem-qty input{
    width: 40px;
    height: 30px;
    border: none;
    text-align: center;
}

.lineitem-qty-btn-right{
    height: 30px;
    width: 40px;
    padding-top: 2px;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    background: var(--secondary-btn-clr);
    border:none;
}

.lineitem-qty-btn-left{
    height: 30px;
    width: 40px;
    padding-top: 2px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    background: var(--secondary-btn-clr);
    border:none;
}

.payment-row{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.payment-label-lg{
    font-weight: bold;
    font-size: 20px;
}

.payment-amt-lg{
    font-weight: bold;
    font-size: 20px;
}

.payment-mode-added{
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}


.sales-page-search{
    display: flex;
    height: 46px;
    border-radius: 32px;
    border: solid 1px var(--btn-border-clr);
    background: var(--secondary-btn-clr);
    width: 100%;
}

.sales-page-search input{
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    height: auto;
    border:none;
    width: 80%;
}

.sales-page-search-btn-grp{
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    display: flex;
}

.sales-page-search-btn-grp .img-btn{
    height: 44px;
    width:44px;
}

.sales-page-search-btn-search{
    text-align: center;
    padding: 12px 20px;
    background: var(--secondary-btn-clr);
    cursor: pointer;
}

.sales-page-search-btn-clear{
    border-top-right-radius: 32px; 
    border-bottom-right-radius: 32px;
    text-align: center;
    padding: 12px 20px;
    background: var(--secondary-btn-clr);
    cursor: pointer;
}

.productlist{
    /* margin-top: 50px; */
}

.productlist-remove{
    position: fixed;
    right: 10px;
}

.productlist-row{
    border-bottom: solid 1px var(--secondary-btn-clr);
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.productlist-name{
    font-size: 13px;
    font-weight: bold;
}

.productlist-sku{
    font-size: 13px;
}


.productlist-price{
    font-size: 13px;
}

.payment-selected-paymentmode{
    padding-bottom:10px;
    font-size: 18px;
}

.payment-total{
    font-size: 35px;
    padding-bottom: 5px;
}

.payment-text{
    font-size: 18px;
    padding-bottom: 10px;
}

.payment-amount{
    padding-left: 0px; 
    padding-right: 0px;
}

.payment-remarks{
    padding-right: 0px;
}


.product-thumbnail{
    height: 46px;
    width: 46px;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 10px;
    margin-right: 10px;
    object-fit: cover;
    border: solid 1px var(--btn-border-clr);
}

.product-thumbnail-text{
    height: 46px;
    width: 46px;
    /* background-color: var(--secondary-btn-clr); */
    background-color: var(--btn-border-clr);    
    margin-right: 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px var(--btn-border-clr);
}

.align-right{
    text-align: right;
}

.sales-screenoption3-lineitem-container{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}