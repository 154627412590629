.popup-menu-overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
    cursor: default;
}

.popup-menu{
    position: fixed;
    bottom:20px;
    z-index: 3000;
    width:100%;
    padding:10px;
}

.popup-menu-options{
    background: white;
    box-shadow: 0 10px 20px 10px rgba(103, 118, 140, 0.25);
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #f7f7f8;
    margin-bottom: 10px;
}

.popup-menu-buttons{
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    text-transform: none;
    font-size: 18px;
    font-weight: 500;
    color: #101928;
    background: white;
    height:46px;
    -webkit-box-shadow: none;
    outline: none;
}

.popup-menu-buttons:not(:first-child) {
    border-top: 1px solid #eef0f2;
}

.delete{
    color: #f67c93;
}