.tabstrip{
    overflow: auto; 
    white-space: nowrap;
    padding:10px;
 }

.tabstrip-button{
    background: white;
    border: solid 1px var(--primary-clr);
    color: var(--primary-clr);
    height: 46px;
    border-radius: 32px;
    margin-right: 5px;
}

.tabstrip-button-selected{
    background: var(--primary-clr);;
    border: solid 1px var(--primary-clr);
    color: white;
    height: 46px;
    border-radius: 32px;
    margin-right: 5px;
}