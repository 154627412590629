.confirmation-overlay{
    background: rgba(103, 118, 140, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
    cursor: default;
}

.confiramtion{
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 5000;

}

.confirmation-close
{
    padding:20px;
}

.confirmation-heading
{
    padding: 70px 0 8px 20px;
    font-weight: 700;
    font-size: 28px;
    color: #101928;
    line-height: 35px;
    width:100%;
}

.confirmation-text{
    font-size: 14px;
    font-weight: 400;
    color: #101928;
    line-height: 18px;
    width:100%;
    padding: 0px 20px;
}

.confirmation-button-section
{
    position: fixed;
    bottom: 0px;
    z-index: 3000;
    width: 100%;
    padding: 20px;
}

.confirmation-button1
{
    /* width:100%;
    font-size: 18px;
    font-weight: 500;
    
    
    text-transform: uppercase;
    height:44px; */


    width: 100%;
    border: none;
    border-radius: 50px;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: none;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #f74969;
    height: 44px;
    outline: none;
}