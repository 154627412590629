.typeAheadSelect-serachitem{
    border-bottom: solid 1px var(--btn-border-clr);
    margin-left: -10px;
    padding: 10px;
    margin-right: -10px;
}

.typeAheadSelect-serachitem .maintext{
    font-weight: bold;
    font-size: 18px;

}

.typeAheadSelect-serachitem .subtext{
    /* font-size: 10px; */
}

.typeAheadSelect-selected{
    display: flex;
    border-radius: 35px;
    background: var(--secondary-btn-clr);
    border: solid 1px var(--btn-border-clr);
}

.typeAheadSelect-selected .maintext{
    padding-left:20px; 
}

.typeAheadSelect-selected .subtext{
    font-size: 10px;
    padding-left:20px;
}


.otp input{
    border: 1px solid #ccc;
    width: 40px !important;
    height: 34px !important;
    border-radius: 10px;
}

.otp span{
    margin: 5px;
}

.input-with-suffix {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .input-with-suffix .form-control {
    padding-right: 2.5rem; /* Adjust based on the size of the suffix */
  }
  
  .input-with-suffix .input-suffix {
    position: absolute;
    right: 0.5rem;
    color: #6c757d; /* Adjust for the desired color */
    pointer-events: none;
  }