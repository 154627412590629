.spinner-container{
    /* width:100%; */
    /* height:50vh; */
    /* text-align: center;
    padding-top:40vh; */

    height: 100%;
    width: 100%;
    position: absolute;
    text-align: center;
    z-index: 1;
    top: 0;
    left: 0;
    
    overflow-x: hidden;
    z-index: 100;
}

.spinner-container-overlay{
  background: rgba(103, 118, 140, 0.5);
}
.spinner-sub-container{
    padding-top:40vh;
}

.spinner {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid var(--primary-clr);
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    display: inline-block
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }