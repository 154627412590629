

.tabstrip{
    overflow: auto; 
    white-space: nowrap;
    padding:10px;
 }

.tabstrip-button{
    /* padding: 15px;
    background: white;
    border: none;
    outline-style:none; */
    background: white;
    border: solid 1px var(--primary-clr);
    color: var(--primary-clr);
    height: 46px;
    border-radius: 32px;
    margin-right: 5px;
}

.tabstrip-button-selected{
    background: var(--primary-clr);;
    border: solid 1px var(--primary-clr);
    color: white;
    height: 46px;
    border-radius: 32px;
    /* padding: 15px;
    background: white;
    color:var(--primary-clr);
    font-weight:bold;
    border-top:none;
    border-left:none;
    border-right:none;
    border-bottom: solid 4px var(--primary-clr);
    outline-style:none; */
}