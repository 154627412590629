
.no-search{
    padding-top:50px;
}

.viewsales-section{
    margin-bottom: 20px;
}
.viewsales-section-header{
    /* padding-top: 40px; */
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
}

.viewsales-table-container{
    overflow-x: auto;
}

.text-align-right
{
    text-align: right;
}
.table tr {
    border-bottom: 1px solid #f4f4f4;
}

.table td {
    padding:10px;
}